/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';

import { Popover2 } from '@blueprintjs/popover2';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineBusinessCenter } from "react-icons/md";
import { useSearchParams } from 'react-router-dom';

import { useHasEmployeeRole, useIdentity } from 'auth/auth.hooks';
import { UpdateBusiness } from 'business/actions';
import { DeleteBusiness } from 'business/actions/delete-business.action';
import { Business } from 'business/business.entities';
import BusinessForm from 'business/components/BusinessForm';
import {
  Alert, Button, Card, CardContent, Grid, Menu, MenuItem, Toast,
} from "common/components";
import { PageContent, PageHeader } from "common/components/page";
import {
  isLoading, isSuccessful, useApiErrors, useApiState, useFormData,
  useInitial,
} from "common/utils";
import styles from "./styles.module.scss";


export default function BusinessPage() {
  const initial = useInitial();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  // store
  const identity = useIdentity();
  const [saveState, dispatch] = useApiState((state) => state.business.save);


  // form data
  const { formData, formErrors, setFormErrors, onChange, changes } =
    useFormData<Business>(identity?.business, null, [identity]);
  useApiErrors(saveState, setFormErrors);

  const updateBusiness = useCallback(() => {
    dispatch(UpdateBusiness(identity!.business!.id!, changes, changes.country != null))
  }, [identity, changes])

  useEffect(() => {
    if (!initial && identity?.business && isSuccessful(saveState)) {
      Toast.showSuccess({ message: ["Business.ItemPage.updatedToast"] });
    }
  }, [saveState]);

  // delete
  const [isConfirmingDeletion, confirmDeletion] = useState(false);
  const [deleteState] = useApiState((store) => store.business.delete);
  useApiErrors(deleteState);
  useEffect(() => {
    if (!initial && deleteState.value) {
      confirmDeletion(false);
      Toast.showSuccess({
        message: ["Business.ItemPage.deletedToast", { business: deleteState.value }],
      });
    }
  }, [deleteState]);

  // access
  const hasEmployeeRole = useHasEmployeeRole();

  return (
    <>
      <PageHeader
        icon={<MdOutlineBusinessCenter size={23} style={{ marginTop: -2 }} />}
        title={identity?.business?.name || "Business.ItemPage.createHeader"}
        backButtonPath={searchParams.get("backButtonPath")}>
        <Button
          text={["save"]}
          intent="primary"
          onClick={updateBusiness}
          hidden={_.isEmpty(changes) || !hasEmployeeRole('admin')}
          loading={isLoading(saveState)}
        />
        <Popover2
          placement="bottom-end"
          interactionKind="click"
          content={
            <Menu>
              <MenuItem
                icon="trash"
                text={["delete"]}
                intent='danger'
                disabled={deleteState.isLoading}
                onClick={() => confirmDeletion(true)} />
            </Menu>
          }>
          <Button
            minimal
            rightIcon="menu"
            hidden={!hasEmployeeRole('admin')}
          />
        </Popover2>
      </PageHeader>
      <PageContent className={styles.wbBusinessPage}>
        <Grid md={2} xs={1} gap={20}>
          <Card elevation={1}>
            <CardContent>
              <BusinessForm
                value={formData}
                errors={formErrors}
                onChange={onChange}
                readOnly={!hasEmployeeRole('admin')}
                disabledFields={[
                  "category",
                ]}
              />
            </CardContent>
          </Card>
        </Grid>
      </PageContent>
      <Alert
        isOpen={isConfirmingDeletion}
        confirmButtonText={['delete']}
        cancelButtonText={['nope']}
        icon="trash"
        intent="danger"
        loading={isLoading(deleteState)}
        onCancel={() => confirmDeletion(false)}
        onConfirm={() => dispatch(DeleteBusiness(identity!.business!.id!))}>
        <p>
          {t("Business.ItemPage.deleteDialogBody", "", {
            business: identity?.business,
          })}
        </p>
      </Alert>
    </>
  );
}
