/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { HTMLInputProps, NumericInput, NumericInputProps } from "@blueprintjs/core";
import { useState } from 'react';

import { useI18n } from "common/i18n/hooks";
import { OnChangeEventHandler } from "common/utils";
import FormField, { FormFieldProps } from "../Field";
import styles from "./styles.module.sass";


export default function NumericInputField({
  className, label, hidden, disabled, error, placeholder,
  name, value, onChange, right, selectAllOnFocus = false, ...rest
}: NumericInputFieldProps) {
  const [buff, setBuff] = useState<string | null>(null);
  const placeholderText = useI18n(placeholder);

  return (
    <FormField
      className={[styles.numericinput, className].join(" ")}
      label={label}
      disabled={disabled}
      hidden={hidden}
      error={error}>
      <NumericInput
        disabled={disabled}
        intent={error ? "danger" : undefined}
        value={buff === null
          ? value?.toLocaleString("fullwide", {
            maximumSignificantDigits: 20,
            useGrouping: false,
          }) : buff}
        placeholder={placeholderText}
        onValueChange={(val, str) => {
          if (onChange == null) return;
          if (str === '') {
            setBuff('');
            onChange({ target: { name, value: null } });
          } else if (str !== val.toLocaleString("fullwide", {
            maximumSignificantDigits: 20,
            useGrouping: false,
          })) {
            setBuff(str);
          } else {
            setBuff(null);
            onChange({ target: { name, value: val } });
          }
        }}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            e.currentTarget.blur();
          }
        }}
        onBlur={() => {
          const str = value?.toLocaleString("fullwide", {
            maximumSignificantDigits: 20,
            useGrouping: false,
          })
          if (buff !== str)
            setBuff(str || '');
        }}
        buttonPosition={rest.readOnly ? "none" : undefined}
        onFocus={(e) => {
          if (selectAllOnFocus)
            setTimeout(() => e.target.select(), 100);
        }}
        rightElement={right}
        {...rest}
      />
    </FormField>
  );
}



export type NumericInputFieldProps = FormFieldProps &
  Omit<NumericInputProps, "placeholder"> &
  Omit<HTMLInputProps, "onChange" | "placeholder"> & {
    name: string,
    onChange?: OnChangeEventHandler<number | null>
    placeholder?: string | [string, object?, string?],
    selectAllOnFocus?: boolean,
    right?: JSX.Element,
    value?: number,
  }