/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';
import slugify from 'slugify';
import QRCode from "react-qr-code";

import { Icon } from '@blueprintjs/core';
import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaMapPin, FaQrcode } from "react-icons/fa";
import { RiBookLine } from "react-icons/ri";
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { saveSvgAsPng } from "save-svg-as-png";

import { useIdentity } from 'auth/auth.hooks';
import { CreateBranch } from 'branch/actions/create-branch.action';
import { DeleteBranch } from 'branch/actions/delete-branch.action';
import { UpdateBranch } from 'branch/actions/update-branch.action';
import { Branch, BranchCatalog } from 'branch/branch.entities';
import { useSyncBranches } from 'branch/branch.hooks';
import BranchCatalogForm from 'branch/components/BranchCatalogForm';
import BranchCatalogMenu from 'branch/components/BranchCatalogMenu';
import BranchForm from 'branch/components/BranchForm';
import { TimeSchedule } from 'common/common.entities';
import {
  Alert, BackActivityIndicator, BackAsyncError, Backout,
  Button, Callout, Card, CardContent, CardHeader, Column,
  DayPicker, Form, Grid, Menu, MenuItem, PageContent,
  PageHeader, PageNotFound, Tab, Tabs, TextInputField,
  Toast,
} from 'common/components';
import {
  ApiCallStateBuilder, isFailed, isLoading, isSuccessful,
  useApiErrors, useApiState, useFormData, useInitial,
} from 'common/utils';
import { AddressForm, AddressSearchField, GoogleMap } from 'geo/components';
import { GoogleMapMarker } from 'geo/components/GoogleMap';
import { GeoAddress, GeoPoint } from 'geo/geo.entities';
import { useGeocodeIp } from 'geo/geo.hooks';
import ManageSubscriptionDialog from 'subscription/components/ManageSubscriptionDialog';
import SubscriptionPlansDialog from 'subscription/components/SubscriptionPlansDialog';
import TimeScheduleForm from '../../components/TimeScheduleForm';
import styles from './styles.module.scss';

var defaultMapZoom = 14;

export default function BranchPage() {
  const { id } = useParams();
  const { t } = useTranslation();
  const initial = useInitial();
  const navigate = useNavigate();
  const identity = useIdentity();
  const { state } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const geocodeIdState = useGeocodeIp();
  const [isDetectingAddressLocation, setDetectingAddressLocation] = useState(false);
  const [isDetectingPickupLocation, setDetectingPickupLocation] = useState(false);
  const [tableNameForQrCode, setTableNameForQrCode] = useState('');
  const [tableQrCodeToDisplay, setTableQrCodeToDisplay] = useState('');

  // store
  const { syncBranchesState, branch } = useSyncBranches({
    find: branch => branch.id === id,
  });
  const [saveState, dispatch] = useApiState((store) => store.branch.save);
  const [deleteState] = useApiState((store) => store.branch.delete);

  // state
  const [isConfirmingDeletion, confirmDeletion] = useState(false);
  const [isSubscriptionPlansDialogVisible, setSubscriptionPlansDialogVisible] = useState(false);
  const [isManageSubscriptionDialogVisible, setManageSubscriptionDialogVisible] = useState(false);

  // form data
  const { formData, onChange, formErrors, changes, setFormErrors } =
    useFormData<Branch>(branch, () => ({
      online: true,
      name: t("Branch.ItemPage.newBranchNameTemplate", {
        business: identity?.business,
        index: (syncBranchesState.value?.length || 0) + 1,
      }),
      keywords: identity?.business?.name + ', ',
      catalogs: [],
      deliveryOptions: {
        inStorePickup: true,
      }
    }), [branch]);
  useApiErrors(saveState, setFormErrors);

  // effects
  useEffect(() => {
    if (!initial) {
      if (isSuccessful(saveState)) {
        if (saveState.value?.status === 'no-subscription') {
          setTimeout(() => setSubscriptionPlansDialogVisible(true), 800);
          return
        }
        if (saveState.value?.status === 'upgrade-subscription') {
          setTimeout(() => setManageSubscriptionDialogVisible(true), 800);
          return
        }
        if (id === "new") {
          navigate("/branches");
          Toast.showSuccess({
            message: ["Branch.ItemPage.createdToast", {
              entity: saveState.value!.entity,
            }]
          });
        } else {
          Toast.showSuccess({ message: ["Branch.ItemPage.updatedToast"] });
        }
      } else if (isFailed(saveState)) {
        if (saveState.error!.code === 'constraint-error') {
          if (saveState.error!.constraints['catalogs']) {
            setSearchParams({ tab: "catalogs" })
          } else if (saveState.error!.constraints['timeSchedule']) {
            setSearchParams({ tab: "time-schedule" })
          } else {
            setSearchParams({ tab: "general" })
          }
        }
      }
    }
  }, [saveState]);

  useEffect(() => {
    if (!initial) {
      if (deleteState.value) {
        Toast.showSuccess({
          message: ["Branch.ItemPage.deletedToast", {
            entity: deleteState.value,
          }]
        });
        navigate('/branches');
      } else if (deleteState.error) {
        Toast.showApiError(deleteState.error);
      }
    }
  }, [deleteState]);

  useEffect(() => {
    if (formData.pickupPoint != null && formData.address != null && (
      (formData.pickupPoint.displayAs == null && formData.address.displayAs != null) ||
      (formData.pickupPoint.latitude == null && formData.address.latitude != null) ||
      (formData.pickupPoint.longitude == null && formData.address.longitude != null))) {
      onChange(({
        target: {
          name: 'pickupPoint',
          value: _.merge({}, formData.address, formData.pickupPoint),
        }
      }))
    }
    if (id === 'new' && formData.timezone == null && geocodeIdState.value?.timezone) {
      onChange({
        target: {
          name: 'timezone',
          value: geocodeIdState.value!.timezone,
        }
      })
    }
  }, [formData, geocodeIdState])

  const [addressMapCenter, addressMapZoom] = useMemo(() => {
    if (formData.address)
      return [formData.address, defaultMapZoom];
    const value = process.env.REACT_APP_GOOGLE_MAP_CENTER;
    let point: GeoPoint;
    if (!value) {
      // Gold Coast
      point = { latitude: -28.0167, longitude: 153.3981 };
    } else {
      const parts = value.split(',').map(part => part.trim());
      point = {
        latitude: parseFloat(parts[0]),
        longitude: parseFloat(parts[1]),
      }
    }
    return [point, 10];
  }, [formData.address?.latitude, formData.address?.longitude]);

  const [pickupMapCenter, pickupMapZoom] = useMemo(() => {
    if (formData.pickupPoint)
      return [formData.pickupPoint, defaultMapZoom];
    if (formData.address)
      return [formData.address, defaultMapZoom];
    const value = process.env.REACT_APP_GOOGLE_MAP_CENTER;
    let point: GeoPoint;
    if (!value) {
      // Gold Coast
      point = { latitude: -28.0167, longitude: 153.3981 };
    } else {
      const parts = value.split(',').map(part => part.trim());
      point = {
        latitude: parseFloat(parts[0]),
        longitude: parseFloat(parts[1]),
      }
    }
    return [point, 10];
  }, [
    formData.pickupPoint?.latitude,
    formData.pickupPoint?.longitude,
    formData.address?.latitude,
    formData.address?.longitude,
  ]);

  const addressMarker = useMemo(() => {
    if (formData.address == null)
      return null;

    return (
      <GoogleMapMarker
        key="addressMarket"
        draggable
        position={{
          lat: formData.address.latitude,
          lng: formData.address.longitude,
        }}
        icon={{
          path: FaMapPin({}).props.children[0].props.d,
          fillColor: "#FF835B",
          fillOpacity: 1,
          strokeWeight: 2,
          strokeColor: "#555",
          scale: 0.08,
          anchor: { x: 150, y: 400, equals: () => false },
        }}
        onDragEnd={(point) => {
          const value: Partial<GeoAddress> = {
            ...formData.address,
            ...point,
          }
          onChange({ target: { name: 'address', value } })
        }}
      />
    );
  }, [formData]);

  const pickupMarker = useMemo(() => {
    if (formData.pickupPoint == null && formData.address == null)
      return null;

    return (
      <GoogleMapMarker
        key="pickupMarker"
        draggable
        position={{
          lat: formData.pickupPoint?.latitude || formData.address!.latitude,
          lng: formData.pickupPoint?.longitude || formData.address!.longitude,
        }}
        icon={{
          path: FaMapPin({}).props.children[0].props.d,
          fillColor: "#FF835B",
          fillOpacity: 1,
          strokeWeight: 2,
          strokeColor: "#555",
          scale: 0.08,
          anchor: { x: 150, y: 400, equals: () => false },
        }}
        onDragEnd={(point) => {
          const value: Partial<GeoAddress> = {
            ...(formData.pickupPoint || formData.address),
            ...point,
          }
          onChange({ target: { name: 'pickupPoint', value } })
        }}
      />
    );
  }, [formData]);

  useEffect(() => {
    if (formData.id == null && !_.isEmpty(formData.name) &&
      (formData.slug === null || formData.slug === undefined)) {
      const slug = slugify(formData.name, { lower: true });
      onChange({
        target: {
          name: 'slug',
          value: slug,
        }
      })
    }
  }, [formData.slug, formData.name]);

  return (
    <>
      <PageHeader
        title={branch?.name || ["Branch.ItemPage.newBranchHeader"]}
        backButtonPath={state?.backpath || "/branches"}>
        <Button
          text={[searchParams.get("tab") === "time-schedule" ? "create" : "next"]}
          intent="success"
          onClick={() => {
            switch (searchParams.get("tab") || "general") {
              case "general":
                setSearchParams({ tab: "catalogs" })
                break;
              case "catalogs":
                setSearchParams({ tab: "time-schedule" })
                break;
              default:
                dispatch(CreateBranch(formData));
            }
          }}
          hidden={id !== 'new' || _.isEmpty(changes)}
          loading={isLoading(saveState)}
        />
        <Button
          text={["save"]}
          intent="success"
          onClick={() => {
            const update = { ...changes };
            if (update.image?.frame) {
              update.image.frame = {
                ...formData.image!.frame,
                ...update.image.frame,
              }
            }
            dispatch(UpdateBranch(branch!, update));
          }}
          hidden={id === 'new' || _.isEmpty(changes)}
          loading={isLoading(saveState)}
        />
        <Popover2
          placement="bottom-end"
          interactionKind="click"
          content={
            <Menu>
              {searchParams.get('tab') !== 'catalogs' ? null : (
                <MenuItem
                  key="add"
                  icon="plus"
                  text={["Branch.ItemPage.Menu.addCatalog"]}
                  onClick={() => {
                    const value = formData.catalogs!.slice();
                    value.push({
                      available: true,
                      catalog: '',
                      merchant: '',
                    });
                    onChange({
                      target: {
                        name: 'catalogs',
                        value: value,
                      },
                    })
                  }} />
              )}
              <MenuItem
                icon="trash"
                text={["Branch.ItemPage.Menu.delete"]}
                intent='danger'
                hidden={branch == null}
                disabled={branch == null}
                onClick={() => confirmDeletion(true)} />
            </Menu>
          }>
          <Button
            minimal
            hidden={branch == null}
            rightIcon="menu" />
        </Popover2>
      </PageHeader>
      <PageContent>
        <ApiCallStateBuilder state={syncBranchesState}
          onLoading={() => <BackActivityIndicator />}
          onError={(error) => <BackAsyncError error={error} />}
          onValue={() => {
            if (!branch && id !== "new")
              return <PageNotFound />
            return (
              <Tabs
                id="branch"
                renderActiveTabPanelOnly
                selectedTabId={searchParams.get('tab') || "general"}
                onChange={(tab: string) => setSearchParams({ tab })}>
                <Tab
                  id="general"
                  title={t("general")}
                  panel={
                    <Grid md={2} xs={1} gap={20}>
                      <Card>
                        <CardHeader title={["general"]} />
                        <CardContent>
                          <BranchForm
                            value={formData}
                            errors={formErrors}
                            readOnly={isLoading(saveState)}
                            onChange={onChange}
                            hiddenFields={[
                              "catalogs",
                              "deliveryOptions.curbsidePickup",
                              "deliveryOptions.inStorePickup",
                              "deliveryOptions.dineIn",
                            ]}
                          />
                        </CardContent>
                      </Card>
                      <Column gap={20}>
                        <Card>
                          <CardHeader title={["deliveryOptions"]} />
                          <CardContent>
                            <BranchForm
                              value={formData}
                              errors={formErrors}
                              readOnly={isLoading(saveState)}
                              onChange={onChange}
                              visibleFields={[
                                "deliveryOptions.curbsidePickup",
                                "deliveryOptions.inStorePickup",
                                "deliveryOptions.dineIn",
                              ]}
                            />
                          </CardContent>
                        </Card>
                        <Card>
                          <CardHeader
                            title={["address"]}
                            right={navigator.geolocation && (
                              <Tooltip2
                                content={t("useCurrentLocation")}
                                position='top-right'>
                                <Button
                                  small
                                  className={styles.locateButton}
                                  icon="locate"
                                  loadingAnimation='circle'
                                  loading={isDetectingAddressLocation}
                                  disabled={isDetectingPickupLocation}
                                  onClick={() => {
                                    setDetectingAddressLocation(true);
                                    navigator.geolocation.getCurrentPosition(position => {
                                      setDetectingAddressLocation(false);
                                      onChange({
                                        target: {
                                          name: 'address',
                                          value: _.merge({}, formData.address, {
                                            latitude: position.coords.latitude,
                                            longitude: position.coords.longitude,
                                          }),
                                        }
                                      }, {
                                        target: {
                                          name: 'pickupPoint',
                                          value: _.merge({}, formData.pickupPoint, {
                                            latitude: position.coords.latitude,
                                            longitude: position.coords.longitude,
                                          }),
                                        }
                                      });
                                    }, (error) => {
                                      setDetectingAddressLocation(false);
                                      Toast.showDanger({ message: ["cannotDetermineLocation"] });
                                    })
                                  }}
                                />
                              </Tooltip2>
                            )}
                          />
                          <div className={styles.searchBar}>
                            <AddressSearchField
                              name={'address'}
                              placeholder={t('search_dots')}
                              onChange={onChange}
                              onError={(error) => {
                                Toast.showDanger({ message: error });
                              }}
                              formProps={{
                                errors: formErrors?.address,
                                readOnly: isLoading(saveState),
                              }}
                            />
                          </div>
                          <GoogleMap
                            id='address'
                            options={{
                              center: {
                                lat: addressMapCenter.latitude,
                                lng: addressMapCenter.longitude,
                              },
                              zoom: addressMapZoom,
                              zoomControl: false,
                              mapTypeControl: false,
                              streetViewControl: false,
                              fullscreenControl: false,
                            }}>
                            {addressMarker}
                          </GoogleMap>
                          <CardContent>
                            <AddressForm
                              name={'address'}
                              value={formData?.address}
                              errors={formErrors?.address}
                              readOnly={isLoading(saveState) || formData?.address?.latitude == null}
                              onChange={onChange}
                              visibleFields={["displayAs"]}
                            />
                          </CardContent>
                        </Card>
                        <Card hidden={formData?.deliveryOptions?.curbsidePickup != true}>
                          <CardHeader
                            title={["pickupPoint"]}
                            right={navigator.geolocation && (
                              <Tooltip2
                                content={t("useCurrentLocation")}
                                position='top-right'>
                                <Button
                                  small
                                  className={styles.locateButton}
                                  icon="locate"
                                  loadingAnimation='circle'
                                  loading={isDetectingPickupLocation}
                                  disabled={isDetectingAddressLocation}
                                  onClick={() => {
                                    setDetectingPickupLocation(true);
                                    navigator.geolocation.getCurrentPosition(position => {
                                      setDetectingPickupLocation(false);
                                      onChange(({
                                        target: {
                                          name: 'pickupPoint',
                                          value: _.merge({}, formData.pickupPoint, {
                                            latitude: position.coords.latitude,
                                            longitude: position.coords.longitude,
                                          }),
                                        }
                                      }))
                                    }, (error) => {
                                      setDetectingPickupLocation(false);
                                      Toast.showDanger({ message: ["cannotDetermineLocation"] });
                                    })
                                  }}
                                />
                              </Tooltip2>
                            )}
                          />
                          <div className={styles.searchBar}>
                            <AddressSearchField
                              name={'pickupPoint'}
                              placeholder={t('search_dots')}
                              onChange={onChange}
                              onError={(error) => {
                                Toast.showDanger({ message: error });
                              }}
                              formProps={{
                                errors: formErrors?.pickupPoint,
                                readOnly: isLoading(saveState),
                              }}
                            />
                          </div>
                          <GoogleMap
                            options={{
                              center: {
                                lat: pickupMapCenter.latitude,
                                lng: pickupMapCenter.longitude,
                              },
                              zoom: pickupMapZoom,
                              zoomControl: false,
                              mapTypeControl: false,
                              streetViewControl: false,
                              fullscreenControl: false,
                            }}>
                            {pickupMarker}
                          </GoogleMap>
                          <CardContent>
                            <AddressForm
                              name={'pickupPoint'}
                              value={formData?.pickupPoint || formData?.address}
                              errors={formErrors?.pickupPoint}
                              readOnly={isLoading(saveState) || formData?.address?.latitude == null}
                              onChange={onChange}
                              visibleFields={["displayAs"]}
                            />
                          </CardContent>
                        </Card>
                        <Card hidden={
                          formData?.deliveryOptions?.dineIn !== true ||
                          (branch?.id == null && _.isEmpty(formData?.slug))}>
                          <CardHeader title={["Branch.ItemPage.QrGenerator.header"]} />
                          <CardContent>
                            <Callout intent='primary'>
                              {["Branch.ItemPage.QrGenerator.callout"]}
                            </Callout>
                            <Form style={{ marginTop: 20 }}>
                              <TextInputField
                                maxLength={4}
                                label={["tableNumber"]}
                                value={tableNameForQrCode}
                                onChange={(e) => {
                                  setTableNameForQrCode(e.target.value);
                                }}
                                onSubmit={() => {
                                  setTableQrCodeToDisplay(
                                    process.env.REACT_APP_SLUG_PREFIX! +
                                    (_.isEmpty(formData?.slug) ? branch?.id : formData.slug) +
                                    '?orderType=dine-in&table=' + tableNameForQrCode);
                                }}
                                right={
                                  <Button small
                                    hidden={!tableNameForQrCode?.length}
                                    text={["generate"]}
                                    onClick={() => {
                                      setTableQrCodeToDisplay(
                                        process.env.REACT_APP_SLUG_PREFIX! +
                                        (_.isEmpty(formData?.slug) ? branch?.id : formData.slug) +
                                        '?orderType=dine-in&table=' + tableNameForQrCode);
                                    }}
                                  />
                                }
                              />
                            </Form>
                            {!tableQrCodeToDisplay?.length ? (
                              <Backout
                                medium
                                icon={<FaQrcode />}
                                message={["Branch.ItemPage.QrGenerator.backoutMsg"]}
                              />
                            ) : (
                              <div className={styles.tableQrCode}>
                                <QRCode
                                  id='table_qr_code'
                                  size={200}
                                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                  value={tableQrCodeToDisplay}
                                  viewBox={`0 0 3200 200`}
                                />
                                <Icon
                                  size={40}
                                  icon="download"
                                  onClick={() => {
                                    saveSvgAsPng(
                                      document.getElementById('table_qr_code'),
                                      `Table_${tableNameForQrCode}_QR.png`,
                                      { scale: 10 },
                                    );
                                  }}
                                />
                              </div>
                            )}
                          </CardContent>
                        </Card>
                      </Column>
                    </Grid>
                  } />
                <Tab
                  id="catalogs"
                  title={t("catalogs")}
                  panel={
                    !formData.catalogs?.length
                      ? (
                        <>
                          <Callout intent='warning'>
                            {["Branch.ItemPage.CatalogsTab.callout"]}
                          </Callout>
                          <Backout
                            className={styles.noBranchCatalogBackout}
                            icon={<RiBookLine />}
                            message={["Branch.ItemPage.CatalogsTab.emptyBackoutMsg"]}
                            action={
                              <Button
                                text={["add"]}
                                onClick={() => {
                                  const value: BranchCatalog[] = [{
                                    available: true,
                                    catalog: '',
                                    merchant: '',
                                  }];
                                  onChange({
                                    target: {
                                      name: 'catalogs',
                                      value,
                                    },
                                  })
                                }} />
                            } />
                        </>
                      ) : (
                        <Grid md={2} xs={1} gap={20}>
                          {formData.catalogs!.map((catalog, index) => (
                            <Card key={`branchCatalog_${catalog.catalog || index}`}>
                              <CardHeader
                                title={["Branch.ItemPage.CatalogsTab.cardTitle", { index: index + 1 }]}
                                right={
                                  <Popover2
                                    placement="bottom-end"
                                    content={
                                      <BranchCatalogMenu
                                        branch={formData}
                                        catalogIndex={index}
                                        onChange={onChange}
                                      />
                                    }>
                                    <Button minimal rightIcon="more" />
                                  </Popover2>
                                }
                              />
                              <CardContent>
                                <BranchCatalogForm
                                  name={`catalogs.${index}`}
                                  value={catalog}
                                  errors={_.get(formErrors?.catalogs, index)}
                                  onChange={onChange}
                                  readOnly={isLoading(saveState)}
                                />
                              </CardContent>
                            </Card>
                          ))}
                        </Grid>
                      )
                  } />
                <Tab
                  id="time-schedule"
                  title={t("timeSchedule")}
                  panel={
                    <>
                      <Callout intent='primary' style={{ marginBottom: 30 }}>
                        {["Branch.ItemPage.TimeScheduleTab.callout"]}
                      </Callout>
                      <Grid md={2} xs={1} gap={20}>
                        <Card>
                          <CardHeader
                            title={["openingHours"]}
                            right={
                              <Button
                                minimal
                                outlined
                                className={styles.cardHeaderButton}
                                intent='warning'
                                text={["open24-7"]}
                                onClick={() => {
                                  const value: TimeSchedule = {
                                    mon: { opensAt: '00:00', closesAt: '00:00' },
                                    tue: { opensAt: '00:00', closesAt: '00:00' },
                                    wed: { opensAt: '00:00', closesAt: '00:00' },
                                    thu: { opensAt: '00:00', closesAt: '00:00' },
                                    fri: { opensAt: '00:00', closesAt: '00:00' },
                                    sat: { opensAt: '00:00', closesAt: '00:00' },
                                    sun: { opensAt: '00:00', closesAt: '00:00' },
                                    daysOff: formData.timeSchedule?.daysOff,
                                  }
                                  onChange({
                                    target: {
                                      name: 'timeSchedule',
                                      value,
                                    }
                                  })
                                }}
                              />
                            }
                          />
                          <CardContent>
                            <TimeScheduleForm
                              name='timeSchedule'
                              value={formData?.timeSchedule}
                              errors={formErrors?.timeSchedule}
                              readOnly={isLoading(saveState)}
                              onChange={onChange}
                            />
                          </CardContent>
                        </Card>
                        <Card>
                          <CardHeader title={["Branch.ItemPage.TimeScheduleTab.daysOffCardHeader"]} />
                          <DayPicker
                            className={styles.daysOffPicker}
                            name='timeSchedule.daysOff'
                            value={formData?.timeSchedule?.daysOff || []}
                            error={formErrors?.timeSchedule?.daysOff}
                            readOnly={isLoading(saveState)}
                            onChange={onChange}
                          />
                        </Card>
                      </Grid>
                    </>
                  } />
              </Tabs>
            )
          }}
        />
        <Alert
          isOpen={isConfirmingDeletion}
          confirmButtonText={['delete']}
          cancelButtonText={['nope']}
          icon="trash"
          intent="danger"
          loading={isLoading(deleteState)}
          onCancel={() => confirmDeletion(false)}
          onConfirm={() => dispatch(DeleteBranch(id!))}>
          <p>Delete this branch?</p>
        </Alert>
        <SubscriptionPlansDialog
          title={t("Branch.ItemPage.PickSubscriptionDialog.title")}
          isOpen={isSubscriptionPlansDialogVisible}
          onClose={() => setSubscriptionPlansDialogVisible(false)}>
          <span className='margin5 error'>* {t("Branch.ItemPage.PickSubscriptionDialog.footer")}</span>
        </SubscriptionPlansDialog>
        <ManageSubscriptionDialog
          title={t("Branch.ItemPage.MySubscriptionDialog.title")}
          isOpen={isManageSubscriptionDialogVisible}
          onClose={() => setManageSubscriptionDialogVisible(false)}
          widgetProps={{
            buttonText: t("Branch.ItemPage.MySubscriptionDialog.button"),
          }}>
          <span className='margin5 error'>* {t("Branch.ItemPage.MySubscriptionDialog.footer")}</span>
        </ManageSubscriptionDialog>
      </PageContent>
    </>
  );
}
