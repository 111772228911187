/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useState } from "react";
import { BsBoxSeam } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import { Button } from "common/components";
import { PageContent, PageHeader } from "common/components/page";
import ProductsGrid from "product/containers/ProductsGrid";


export default function ProductsPage() {
  const navigate = useNavigate()
  const [searchQuery, setSearchQuery] = useState<string | null>(null);

  return (
    <>
      <PageHeader
        icon={<BsBoxSeam size={20} style={{ marginRight: 2 }} />}
        title={['products']}
        onSearchQueryChange={setSearchQuery} >
        <Button
          text={["create"]}
          intent="success"
          onClick={() => navigate('new')}
        />
      </PageHeader>
      <PageContent>
        <ProductsGrid filterQuery={searchQuery} />
      </PageContent>
    </>
  );
}
