/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import React from "react";
import { Route, Routes } from "react-router-dom";

import AuthGuard from "auth/guards/Auth";
import ProfilePage from "auth/pages/ProfilePage";
import BranchPage from "branch/pages/BranchPage";
import BranchesPage from "branch/pages/BranchesPage";
import BusinessGuard from "business/guards/BusinessGuard";
import BusinessPage from "business/pages/BusinessPage";
import CatalogPage from "catalog/pages/CatalogPage";
import CatalogsPage from "catalog/pages/CatalogsPage";
import ImportMenuPage from "catalog/pages/ImportMenuPage";
import CategoriesPage from "category/pages/CategoriesPage";
import CategoryPage from "category/pages/CategoryPage";
import WorkspaceLayout from "common/layouts/Workspace";
import Page404 from "common/pages/404";
import ApplicationsPage from "common/pages/Applications";
import DigitalMarketingAssetsPage from "common/pages/DigitalMarketingAssets";
import EmployeePage from "employee/pages/EmployeePage";
import EmployeesPage from "employee/pages/EmployeesPage";
import BasicMerchantAccountPage from "merchant/basic/pages/BasicMerchantAccountPage";
import MerchantAccountsPage from "merchant/common/pages/MerchantAccountsPage";
import StripeMerchantAccountPage from "merchant/stripe/pages/StripeMerchantAccountPage";
import ActiveOrdersPage from "order/pages/ActiveOrdersPage";
import DineInOrderPage from "order/pages/DineInOrderPage";
import OrderHistoryPage from "order/pages/OrderHistoryPage";
import PickupOrderPage from "order/pages/PickupOrderPage";
import PreparePickupOrderPage from "order/pages/PreparePickupOrderPage";
import ProductPage from "product/pages/ProductPage";
import ProductsPage from "product/pages/ProductsPage";
import BasicSalesReport from "report/pages/BasicSalesReport";
import PeriodicalSalesReport from "report/pages/PeriodicalSalesReport";
import SubscriptionPage from "subscription/pages/SubscriptionPage";
import TablePage from "table/pages/TablePage";
import TablesPage from "table/pages/TablesPage";

const EditUserPage = React.lazy(() => import("admin/user/pages/EditUserPage"));
const UsersPage = React.lazy(() => import("admin/user/pages/UsersPage"));

const OnLogin = React.lazy(() => import("auth/pages/OnLogin"));
const PincodePage = React.lazy(() => import("auth/pages/Pincode"));

export default function Router() {
  return (
    <Routes>
      <Route path="/on-login" element={<OnLogin />} />
      <Route element={<AuthGuard />}>
        <Route element={<BusinessGuard layout={(child) => <WorkspaceLayout>{child}</WorkspaceLayout>} />}>
          <Route path="/" element={<ActiveOrdersPage />} />
          <Route path="/apps" element={<ApplicationsPage />} />
          <Route path="/digital-marketing-assets" element={<DigitalMarketingAssetsPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/business" element={<BusinessPage />} />
          <Route path="/branches" element={<BranchesPage />} />
          <Route path="/branches/:id" element={<BranchPage />} />
          <Route path="/pincode" element={<PincodePage />} />
          <Route path="/catalogs" element={<CatalogsPage />} />
          <Route path="/catalogs/:id" element={<CatalogPage />} />
          <Route path="/products" element={<ProductsPage />} />
          <Route path="/products/:id" element={<ProductPage />} />
          <Route path="/import-menu" element={<ImportMenuPage />} />
          <Route path="/categories" element={<CategoriesPage />} />
          <Route path="/categories/:id" element={<CategoryPage />} />
          <Route path="/subscription" element={<SubscriptionPage />} />
          <Route path="/merchants" element={<MerchantAccountsPage />} />
          <Route path="/merchants/basic/:id" element={<BasicMerchantAccountPage />} />
          <Route path="/merchants/stripe/:id" element={<StripeMerchantAccountPage />} />
          <Route path="/orders" element={<ActiveOrdersPage />} />
          <Route path="/order-history" element={<OrderHistoryPage />} />
          <Route path="/orders/pickup/new" element={<PreparePickupOrderPage />} />
          <Route path="/orders/pickup/:id" element={<PickupOrderPage />} />
          <Route path="/orders/dine-in/:id" element={<DineInOrderPage />} />
          <Route path="/order-history/pickup/:id" element={<PickupOrderPage />} />
          <Route path="/employees" element={<EmployeesPage />} />
          <Route path="/employees/:id" element={<EmployeePage />} />
          <Route path="/tables" element={<TablesPage />} />
          <Route path="/tables/:id" element={<TablePage />} />
          <Route path="/reports/sales/basic" element={<BasicSalesReport />} />
          <Route path="/reports/sales/daily" element={<PeriodicalSalesReport period='day' />} />
          <Route path="/reports/sales/weekly" element={<PeriodicalSalesReport period='week' />} />
          <Route path="/reports/sales/monthly" element={<PeriodicalSalesReport period='month' />} />
        </Route>
        <Route element={<AuthGuard role='admin' layout={(child) => <WorkspaceLayout>{child}</WorkspaceLayout>} />}>
          <Route path="/admin/users" element={<UsersPage />} />
          <Route path="/admin/users/:id" element={<EditUserPage />} />
        </Route>
      </Route>
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}
