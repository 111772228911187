/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';
import { BsBoxSeam } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import BranchCard from 'branch/components/BranchCard';
import {
  BackAsyncError, Backout, Button, Grid, GridContentLoader,
} from "common/components";
import { ApiCallStateBuilder } from "common/utils";
import { Branch } from '../../branch.entities';
import { useSyncBranches } from "../../branch.hooks";


export default function BranchesGrid({ filterQuery }: BranchesGridProps) {
  const navigate = useNavigate();
  const { syncBranchesState, branches } = useSyncBranches({
    filter: (branch) =>
      _.isEmpty(filterQuery) || _.values(branch).join(" ").toLowerCase().includes(filterQuery!),
  });

  return (
    <ApiCallStateBuilder<Branch[]>
      state={syncBranchesState}
      onLoading={() => <GridContentLoader md={3} sm={2} xs={1} gap={20} />}
      onError={(error) => <BackAsyncError error={error} />}
      onValueEmpty={() => (
        <div style={{ marginTop: 150 }}>
          <Backout
            icon={<BsBoxSeam style={{ marginTop: -3, marginLeft: -2 }} />}
            title={["Branch.Grid.noBranchesTitle"]}>
            <div style={{ marginTop: 20 }}>
              <Button
                outlined
                text={["create"]}
                onClick={() => navigate("/branches/new")} />
            </div>
          </Backout>
        </div>
      )}
      onValueNotEmpty={() =>
        <Grid md={3} sm={2} xs={1} gap={20} >
          {branches.map(branch =>
            <BranchCard
              key={branch.id}
              value={branch}
              onClick={() => navigate(branch.id!)}
            />
          )}
        </Grid>
      }
    />
  );
}

export type BranchesGridProps = {
  filterQuery?: string | null;
}
