/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Alignment, Navbar, Position } from "@blueprintjs/core";
import { Popover2, Tooltip2 } from "@blueprintjs/popover2";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaRegUser } from "react-icons/fa";
import { PiPasswordBold } from 'react-icons/pi';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ApiState } from "app.store";
import { Logout } from "auth/auth.store";
import { LoadMyBusinessesAction } from "business/actions/load-my-businesses.action";
import { SetCurrentBusinessAction } from "business/actions/set-current-business.action";
import { Avatar, Menu, MenuItem } from "common/components";
import { isEmpty, isSuccessful, useApiErrors } from "common/utils";
import styles from "./styles.module.sass";


export default function Topbar({ className, menuButton }: TopbarProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const classes = [styles.topbar, className].join(" ");

  const [isEstMenuOpen, openEstMenu] = useState(false);

  const loginState = useSelector((state: ApiState) => state.auth.login);
  const loadMyBusinessesState = useSelector((state: ApiState) => state.business.loadMyBusinesses);
  const setCurrentBusinessState = useSelector((state: ApiState) => state.business.setCurrentBusiness);
  const currentBusiness = useMemo(() => loginState.value?.business, [loginState]);

  useEffect(() => {
    if (isEmpty(loadMyBusinessesState)) {
      dispatch(LoadMyBusinessesAction());
    }
  }, [loadMyBusinessesState])

  useApiErrors(setCurrentBusinessState)

  return (
    <Navbar className={classes}>
      <Navbar.Group align={Alignment.LEFT}>
        {menuButton}
        <Navbar.Heading>
          <Tooltip2
            content={t("appVersion", { version: process.env.REACT_APP_VERSION })}
            position="right"
            hoverOpenDelay={3000}>
            <>
              <img
                className="logo"
                src="/images/logo512.png"
                alt="logo"
                onClick={() => navigate("/")}
              />
              <img
                className="logo_title"
                src="/images/title1024.png"
                alt="logo"
                onClick={() => navigate("/")}
              />
            </>
          </Tooltip2>
        </Navbar.Heading>
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        {currentBusiness && (
          <Popover2
            position={Position.BOTTOM_RIGHT}
            isOpen={isEstMenuOpen}
            onInteraction={openEstMenu}
            content={
              <Menu>
                {(loadMyBusinessesState.value || [])
                  .map((business) => (
                    <MenuItem
                      key={`business_${business.id}`}
                      icon={business.id !== currentBusiness.id ? "nest" : "cog"}
                      text={business.name}
                      onClick={(e) => {
                        openEstMenu(false);
                        if (business.id !== currentBusiness.id) {
                          dispatch(SetCurrentBusinessAction(business))
                        } else {
                          navigate('/business');
                        }
                      }}
                    />
                  ))}
              </Menu>
            }>
            <Avatar
              className={"businessAvatar"}
              size={32}
              name={currentBusiness.name}
            />
          </Popover2>
        )}
        {isSuccessful(loginState) && (
          <Popover2
            position={Position.BOTTOM_RIGHT}
            content={
              <Menu>
                <MenuItem
                  icon={<PiPasswordBold />}
                  text={["pincode"]}
                  onClick={(e) => {
                    openEstMenu(false);
                    navigate('/pincode');
                  }}
                />
                <MenuItem
                  icon={<FaRegUser />}
                  text={["profile"]}
                  onClick={(e) => {
                    openEstMenu(false);
                    navigate('/profile');
                  }}
                />
                <MenuItem
                  icon="log-out"
                  text={["logout"]}
                  onClick={() => dispatch(Logout())}
                />
              </Menu>
            }>
            <Avatar
              className="userAvatar"
              size={32}
              name={loginState.value?.user.name}
              picture={loginState.value?.user.picture}
            />
          </Popover2>
        )}
      </Navbar.Group>
    </Navbar>
  );
}

export type TopbarProps = {
  className?: string,
  menuButton?: JSX.Element,
}