/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';
import { useMemo } from "react";

import { Form, InfoTooltip, SwitchField, TextInputField } from "common/components";
import { EntityFormProps } from "common/components/form/Form";
import { useFieldName, useIsFieldHidden, useIsReadOnlyField } from "common/utils";
import { MerchantAccount } from "merchant/merchant.entities";
import styles from "./styles.module.sass";


export default function MerchantAccountForm<T extends MerchantAccount>({
  className, value, errors, onChange, children, ...rest
}: MerchantAccountFormProps<T>) {
  const classes = useMemo(() => [styles.merchantaccountForm, className].join(" "), [className]);
  const fieldName = useFieldName(rest);
  const isHidden = useIsFieldHidden(rest);
  const isReadOnly = useIsReadOnlyField(rest);

  return (
    <Form className={classes} {...rest}>
      <TextInputField
        maxLength={25}
        name={fieldName("name")}
        label={["name"]}
        value={value?.name}
        error={errors?.name}
        readOnly={isReadOnly("name")}
        hidden={isHidden("name")}
        onChange={onChange}
      />
      <TextInputField
        maxLength={30}
        name={fieldName("displayName")}
        label={["Merchant.Form.businessDisplayName"]}
        actions={
          <InfoTooltip
            position="top-right"
            iconProps={{ style: { marginBottom: 1 } }}
            content={['Merchant.Form.businessDisplayNameTooltip']}
          />
        }
        value={value?.displayName}
        error={errors?.displayName}
        readOnly={isReadOnly("displayName")}
        hidden={value?.type === 'basic' || isHidden("displayName")}
        onChange={onChange}
      />
      <TextInputField
        maxLength={5}
        name={fieldName("currency")}
        label={["currency"]}
        value={value?.currency}
        error={errors?.currency}
        readOnly={isReadOnly("currency")}
        hidden={isHidden("currency")}
        onChange={onChange}
      />
      <SwitchField
        name={fieldName("uponArrivalPayments")}
        label={["Merchant.Form.uponArrivalPayments"]}
        value={value?.uponArrivalPayments === true}
        error={errors?.uponArrivalPayments}
        readOnly={isReadOnly("uponArrivalPayments")}
        disabled={value?.uponArrivalPayments === null}
        hidden={isHidden("uponArrivalPayments")}
        onChange={onChange}
      />
      <SwitchField
        name={fieldName("atCounterPayments")}
        label={["Merchant.Form.atCounterPayments"]}
        value={value?.atCounterPayments === true}
        error={errors?.atCounterPayments}
        readOnly={isReadOnly("atCounterPayments")}
        disabled={value?.atCounterPayments === null}
        hidden={isHidden("atCounterPayments")}
        onChange={onChange}
      />
      <SwitchField
        name={fieldName("cardPayments")}
        label={["Merchant.Form.cardPayments"]}
        value={value?.cardPayments === true}
        error={errors?.cardPayments}
        readOnly={isReadOnly("cardPayments")}
        disabled={value?.cardPayments === null}
        hidden={true || isHidden("cardPayments")}
        onChange={onChange}
      />
      <SwitchField
        name={fieldName("applePay")}
        label={["Merchant.Form.applePay"]}
        value={value?.applePay === true}
        error={errors?.applePay}
        readOnly={isReadOnly("applePay")}
        disabled={value?.applePay === null}
        hidden={true || isHidden("applePay")}
        onChange={onChange}
      />
      <SwitchField
        name={fieldName("googlePay")}
        label={["Merchant.Form.googlePay"]}
        value={value?.googlePay === true}
        error={errors?.googlePay}
        readOnly={isReadOnly("googlePay")}
        disabled={value?.googlePay === null}
        hidden={true || isHidden("googlePay")}
        onChange={onChange}
      />
      <SwitchField
        name={fieldName("paymentProcessingFee")}
        label={["Merchant.Form.paymentProcessingFee"]}
        value={value?.paymentProcessingFee === true || _.isObject(value?.paymentProcessingFee)}
        error={errors?.paymentProcessingFee}
        readOnly={isReadOnly("paymentProcessingFee")}
        // hidden={true}
        hidden={value?.type != 'stripe' || isHidden("paymentProcessingFee")}
        onChange={onChange}
      />
      {children}
    </Form>
  );
}

export type MerchantAccountFormProps<T extends MerchantAccount> = EntityFormProps<T> & {
};