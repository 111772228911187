/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useMemo } from "react";

import { Form, FormField, NumericField, SwitchField, TextAreaField, TextInputField } from "common/components";
import { EntityFormProps } from "common/components/form/Form";
import { useFieldName, useIsFieldHidden } from "common/utils";
import { ExImageField } from "media/components";
import OptionGroupForm from "product/components/OptionGroupForm";
import { Product } from "product/product.entities";
import styles from "./styles.module.sass";


export default function ProductForm<T extends Product>({
  className, readOnly, value, errors, onChange,
  children, ...rest
}: ProductFormProps<T>) {
  const classes = useMemo(() => [styles.productForm, className].join(" "), [className]);
  const fieldName = useFieldName(rest);
  const isHidden = useIsFieldHidden(rest);

  return (
    <Form className={classes} {...rest}>
      <SwitchField
        name={fieldName("available")}
        label={["available"]}
        value={value?.available}
        error={errors?.available}
        readOnly={readOnly}
        hidden={isHidden("available")}
        onChange={onChange}
      />
      <ExImageField
        name={fieldName("image")}
        label={["image"]}
        value={value?.image}
        error={errors?.image}
        readOnly={readOnly}
        hidden={isHidden("image")}
        onChange={onChange}
        frameProps={{
          height: 188,
          width: 216,
        }} />
      <TextInputField
        maxLength={25}
        name={fieldName("name")}
        label={["name"]}
        value={value?.name}
        error={errors?.name}
        readOnly={readOnly}
        hidden={isHidden("name")}
        onChange={onChange}
      />
      <TextAreaField
        maxLength={150}
        name={fieldName("description")}
        label={["description"]}
        value={value?.description}
        error={errors?.description}
        readOnly={readOnly}
        hidden={isHidden("description")}
        onChange={onChange} />
      <NumericField
        name={fieldName("price")}
        label={["price"]}
        value={value?.price}
        error={errors?.price}
        readOnly={readOnly}
        hidden={isHidden("price")}
        onChange={onChange}
        stepSize={.1}
        majorStepSize={1}
        minorStepSize={.01}
      />
      <TextInputField
        maxLength={20}
        name={fieldName("sku")}
        label={["sku"]}
        autoCapitalize="none"
        value={value?.sku}
        error={errors?.sku}
        readOnly={readOnly}
        hidden={isHidden("sku")}
        onChange={onChange}
      />
      <NumericField
        name={fieldName("etr")}
        label={["etr"]}
        value={value?.etr}
        error={errors?.etr}
        readOnly={readOnly}
        hidden={isHidden("etr")}
        onChange={onChange}
      />
      <FormField
        label={["optionGroups"]}
        hidden={isHidden("optionGroups")} >
        {(value?.optionGroups || []).map((optionGroup, i) => (
          <OptionGroupForm
            key={`optionGroup_${i}`}
            name={fieldName(`optionGroups.${i}`)}
            value={optionGroup}
            errors={errors?.optionGroups}
            readOnly={readOnly}
            // hidden={isHidden(`optionGroups.${i}`)}
            onChange={onChange}
          />
        ))}
      </FormField>
      {children}
    </Form>
  );
}

export type ProductFormProps<T extends Product> = EntityFormProps<T> & {
};