/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { BsBoxSeam } from "react-icons/bs";
import { MdOutlineCategory } from "react-icons/md";
import QRCode from "react-qr-code";
import { saveSvgAsPng } from "save-svg-as-png";

import { Branch } from "branch/branch.entities";
import { useSyncBranches } from "branch/branch.hooks";
import {
  BackAsyncError, Backout, Button, Card, CardContent,
  CardHeader, ClipboardButton, Grid, GridContentLoader, PageContent, PageHeader,
} from "common/components";
import { ApiCallStateBuilder } from "common/utils";
import styles from './styles.module.scss';

export default function () {
  const { syncBranchesState } = useSyncBranches({});

  return (
    <>
      <PageHeader
        icon={<MdOutlineCategory size={22} style={{ marginRight: 2, marginTop: -1.5 }} />}
        title={['DigitalMarketingAssets.header']}>
      </PageHeader>
      <PageContent>
        <Grid md={2} xs={1} gap={20}>
          <Card>
            <CardHeader title={["DigitalMarketingAssets.dishdashAssets"]} />
            <CardContent className={styles.logoFrame}>
              <a href="/images/marketing-assets/fua_logo.png" target="_blank">
                <img
                  id="fua_logo"
                  alt="fua-logo"
                  src="/images/marketing-assets/fua_logo.png"
                />
              </a>
              <br />
              <a href="/images/marketing-assets/fua_logo_light.png" target="_blank">
                <img
                  alt="fua-logo"
                  src="/images/marketing-assets/fua_logo_light.png"
                />
              </a>
              <br />
              <a href="/images/marketing-assets/fua_logo_dark.png" target="_blank">
                <img
                  alt="fua-logo"
                  src="/images/marketing-assets/fua_logo_dark.png"
                />
              </a>
            </CardContent>
          </Card>
          <Card>
            <CardHeader title={["DigitalMarketingAssets.branchAssets"]} />
            <CardContent>
              <ApiCallStateBuilder<Branch[]>
                state={syncBranchesState}
                onLoading={() => <GridContentLoader md={3} sm={2} xs={1} gap={20} />}
                onError={(error) => <BackAsyncError error={error} />}
                onValueEmpty={() => (
                  <div style={{ marginTop: 150 }}>
                    <Backout
                      icon={<BsBoxSeam style={{ marginTop: -3, marginLeft: -2 }} />}
                      title={["Branch.Grid.noBranchesTitle"]}>
                    </Backout>
                  </div>
                )}
                onValueNotEmpty={(branches) => {
                  return (
                    <div>
                      {branches.map(branch =>
                        <div className={styles.branchFrame}>
                          <b>{branch.name}:</b>
                          <QRCode
                            id={`${branch.id}_qr_code`}
                            size={180}
                            style={{ height: "180px", width: "180px" }}
                            value={process.env.REACT_APP_SLUG_PREFIX! + (branch.slug || branch.id)}
                            viewBox={`0 0 350 350`}
                          />
                          <Button
                            icon="download"
                            text={["DigitalMarketingAssets.saveQrCode"]}
                            onClick={() => {
                              saveSvgAsPng(
                                document.getElementById(`${branch.id}_qr_code`),
                                `DishDash ${branch.name} QR.png`,
                                { scale: 10 },
                              );
                            }}
                          />
                          <ClipboardButton
                            minimal={false}
                            text={["DigitalMarketingAssets.copyStoreLink"]}
                            textToCopy={process.env.REACT_APP_SLUG_PREFIX! + (branch.slug || branch.id)}
                          />
                        </div>
                      )}
                    </div>
                  );
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </PageContent>
    </>
  );
}
