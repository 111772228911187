/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';
import { RiBookLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

import CatalogCard from 'catalog/components/CatalogCard';
import {
  BackAsyncError, Backout, Button, Grid, GridContentLoader
} from "common/components";
import { ApiCallStateBuilder } from "common/utils";
import { useTranslation } from 'react-i18next';
import { Catalog } from '../../catalog.entities';
import { useSyncCatalogs } from "../../catalog.hooks";
import styles from "./styles.module.scss";


export default function CatalogsGrid({ filterQuery }: CatalogsGridProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { syncCatalogsState, catalogs } = useSyncCatalogs({
    filter: (catalog) =>
      _.isEmpty(filterQuery) || _.values(catalog).join(" ").toLowerCase().includes(filterQuery!),
  });

  return (
    <ApiCallStateBuilder<Catalog[]>
      state={syncCatalogsState}
      onLoading={() => <GridContentLoader md={3} sm={2} xs={1} gap={20} />}
      onError={(error) => <BackAsyncError error={error} />}
      onValueEmpty={() => (
        <div style={{ marginTop: 150 }}>
          <Backout
            icon={
              <RiBookLine style={{ marginTop: -3, marginLeft: -2 }} />
            }
            title={["Catalog.Grid.emptyBackoutTitle"]}>
            <div style={{ marginTop: 20 }}>
              <Button
                outlined
                text={["Create"]}
                onClick={() => navigate("/catalogs/new")} />
            </div>
          </Backout>
        </div>
      )}
      onValueNotEmpty={() =>
        <Grid md={3} sm={2} xs={1} gap={20} >
          {catalogs.map(catalog =>
            <CatalogCard
              interactive
              key={catalog.id}
              className={styles.card}
              onClick={() => navigate(catalog.id!)}
              value={catalog} />
          )}
        </Grid>
      }
    />
  );
}

export type CatalogsGridProps = {
  filterQuery?: string | null;
}
