/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';

import { MenuItem } from "@blueprintjs/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ApiState } from 'app.store';
import { Button, Toast } from 'common/components';
import { SuggestionsField } from 'common/components/form';
import { FormFieldProps } from "common/components/form/Field";
import {
  OnChangeEventHandler, isFailed, isLoading, isSuccessful,
  useInitial,
} from 'common/utils';
import { GetAddressDetails } from 'geo/actions/get-address-details.action';
import { SearchAddress } from '../../actions/search-address.action';
import { AddressFormProps } from "../AddressForm";
import styles from "./styles.module.sass";


export default function AddressSearchField({
  className, name, manualDefault, onChange,
  formProps, onError, ...rest
}: AddressSearchFieldProps) {
  // context
  const dispatch = useDispatch();
  const initial = useInitial();
  const { t } = useTranslation();

  const classes = useMemo(() =>
    [styles.searchAddressField, className].join(" ").trim(),
    [className]);
  const componentId = useMemo(() => Math.random().toString(), []);

  // store
  const searchState = useSelector((state: ApiState) => state.geo.searchAddress);
  const getAddressDetailsState = useSelector((state: ApiState) => state.geo.getAddressDetails);

  // states
  const [searchQuery, setSearchQuery] = useState('');
  const [waitingAddressDetailsWithId, setWaitingAddressDetailsWithId] = useState('');

  const error = useMemo(() => {
    var errorText: string | undefined = undefined;
    if (!initial && isSuccessful(searchState)
      && searchState.value!.meta.query === searchQuery
      && _.isEmpty(searchState.value?.suggestions))
      errorText = t("Address not found");
    else if (!initial && isFailed(getAddressDetailsState)) {
      const error = getAddressDetailsState.error!;
      errorText = t(`ApiErrors.${error.code}`, error.code, error);
    }
    if (onError && errorText) onError(errorText);
    return errorText;
  }, [initial, searchQuery, searchState, getAddressDetailsState])

  // callbacks
  const searchAddress = useCallback(() => {
    if (!_.isEmpty(searchQuery))
      dispatch(SearchAddress(searchQuery, componentId));
  }, [searchQuery]);

  // effects
  useEffect(() => {
    if (!initial && isFailed(searchState)) {
      Toast.showApiError(searchState.error!, {});
    }
  }, [searchState]);
  useEffect(() => {
    if (!initial && getAddressDetailsState.value?.meta?.id === waitingAddressDetailsWithId) {
      onChange({ target: { name, value: getAddressDetailsState.value!.address } });
      setWaitingAddressDetailsWithId('');
    }
  }, [getAddressDetailsState, getAddressDetailsState])

  return (
    <>
      <SuggestionsField
        className={classes}
        query={searchQuery}
        suggestions={
          searchState.value?.meta.query === searchQuery &&
            searchState.value?.meta.componentId === componentId
            ? searchState.value!.suggestions
            : undefined
        }
        renderSuggestion={(address, closePopover) =>
          <MenuItem
            key={address.formattedAddress}
            text={address.formattedAddress}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                closePopover();
                setWaitingAddressDetailsWithId(address.addressId);
                dispatch(GetAddressDetails(address.addressId))
              }
            }}
            onClick={() => {
              closePopover();
              setWaitingAddressDetailsWithId(address.addressId);
              dispatch(GetAddressDetails(address.addressId))
            }} />
        }
        onQueryChange={setSearchQuery}
        onSubmit={searchAddress}
        error={error}
        right={
          <Button minimal
            icon="search"
            loading={isLoading(searchState)}
            onClick={searchAddress} />
        }
        {...rest}
      />
    </>
  );
}

export type AddressSearchFieldProps = FormFieldProps & {
  name: string,
  // value?: GeoAddress,
  placeholder?: string,
  manualDefault?: boolean,
  formProps: Omit<AddressFormProps, "value" | "onChange" | "name" | "namePrefix">,
  onChange: OnChangeEventHandler<any>,
  onError?: (error: string) => void,
}