/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Business } from "business/business.entities";
import { ApiAction, failed, loading, success } from "common/utils";

import { SaveBusinessState } from "../business.store";


export function CreateBusiness(business: Business, relogin: boolean = false): ApiAction {
  return async (dispatch, _, api) => {
    dispatch(SaveBusinessState(loading(business)));
    try {
      const { value } = await api.post('/businesses', {
        params: { relogin },
        data: business,
      })
      dispatch(SaveBusinessState(success(value)));
    } catch (error) {
      dispatch(SaveBusinessState(failed(error)));
    }
  };
}
