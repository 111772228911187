/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Profile } from "auth/auth.entities";
import { EntityForm, InfoTooltip } from "common/components";
import { EntityFormProps } from "common/components/form/EntityForm";
import styles from "./styles.module.sass";


export default function ProfileForm<T extends Profile>({
  className, readOnly, value, errors, onChange,
  children, fields, ...rest
}: ProfileFormProps<T>) {
  const classes = useMemo(() =>
    [styles.baseProfileForm, className].join(" "),
    [className]);

  return (
    <EntityForm
      className={classes}
      value={value}
      errors={errors}
      onChange={onChange}
      fields={[{
        type: "input",
        name: "name",
        label: ["name"]
      }, {
        type: "input",
        name: "email",
        label: ["email"],
        autoCapitalize: 'none',
        readOnly: value?.source === 'email',
        actions: (
          <InfoTooltip
            content={['Profile.Form.emailHint']}
            position="top-right"
            iconProps={{ style: { marginBottom: 1 } }} />
        )
      }, {
        label: ["phoneNumber"],
        type: "ex-phone",
        name: "phone",
        readOnly: value?.source === 'phone',
        actions: (
          <InfoTooltip
            content={['Profile.Form.phoneHint']}
            position="top-right"
            iconProps={{ style: { marginBottom: 1 } }} />
        )
      }]}
      {...rest}>
      {children}
    </EntityForm>
  );
}

export type ProfileFormProps<T extends Profile> = EntityFormProps<T> & {
};