/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';

import { Callout, Dialog, DialogBody, DialogFooter } from "@blueprintjs/core";
import { useCallback, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { GoDependabot } from "react-icons/go";
import { PiMagicWand } from "react-icons/pi";

import { extractMenu } from "catalog/actions/extract-menu.action";
import { importMenu } from "catalog/actions/import-menu.action";
import { ImportedCategory } from "catalog/catalog.entities";
import { ExtractMenuState } from 'catalog/catalog.store';
import { Button, Grid, Toast } from "common/components";
import { PageContent, PageHeader } from "common/components/page";
import { useApiErrors, useApiState, useInitial } from "common/utils";
import { ExImageEditor } from "media/components";
import { ExImage } from "media/media.entities";
import ProductCard from "product/components/ProductCard";
import ProductForm from "product/components/ProductForm";
import { Product } from "product/product.entities";
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';


export default function () {
  const initial = useInitial();
  const { t } = useTranslation(undefined, { keyPrefix: "Catalog.ImportMenuPage" });
  const [value, setValue] = useState<ImportedCategory[] | undefined>();
  const [importImage, setImportImage] = useState<ExImage | null>(null);
  const [editingProduct, setEditingProduct] = useState<Product & { _name: string, _errors?: any } | undefined>();

  // extract state
  const [extractMenuState, dispatch] = useApiState((store) => store.catalog.extractMenu);
  useApiErrors(extractMenuState);
  useEffect(() => {
    if (extractMenuState.value) {
      if (extractMenuState.value.categories.length) {
        setImportImage(null);
        setValue(extractMenuState.value.categories.slice());
        dispatch(ExtractMenuState({}));
      } else {
        Toast.showWarning({ message: ['Catalog.ImportMenuPage.noItemsExtractedToast'] });
      }
    }
  }, [extractMenuState]);

  // import state
  const [constraintsErrors, setConstraintsErrors] = useState({});
  const [importMenuState] = useApiState((store) => store.catalog.importMenu);
  useApiErrors(importMenuState, setConstraintsErrors);
  useEffect(() => {
    if (!initial && importMenuState.value) {
      const categories = importMenuState.value.categories;
      const productsCount = categories.reduce((sum, cat) => sum + cat.products.length, 0);
      const categoriesCount = categories.length;
      Toast.showSuccess({
        message: ["Catalog.ImportMenuPage.savedItemsToast", { productsCount, categoriesCount }],
      });
      setValue(undefined);
    } else if (importMenuState.error &&
      importMenuState.error.code === 'constraint-error') {
      Toast.showDanger({ message: ["Catalog.ImportMenuPage.invalidDataToast"] });
    }
  }, [importMenuState]);


  const onDragEnd = useCallback((result: DropResult) => {
    if (value && result.destination) {
      const srcIdx = parseInt(result.source.droppableId);
      const destIdx = parseInt(result.destination.droppableId);
      const newValues = value.slice();
      const product = newValues[srcIdx].products.find(p => p.id === result.draggableId);
      if (product) {
        newValues[srcIdx] = {
          name: newValues[srcIdx].name,
          products: newValues[srcIdx].products.filter(p => p != product),
        };
        newValues[destIdx] = {
          name: newValues[destIdx].name,
          products: [...newValues[destIdx].products, product],
        };
        setValue(newValues);
      }
    }
  }, [value]);

  return (
    <>
      <PageHeader
        icon={<PiMagicWand size={20} style={{ marginRight: 2 }} />}
        title={['Catalog.ImportMenuPage.header']} >
        <Button
          text={
            extractMenuState.isLoading
              ? ["Catalog.ImportMenuPage.extractingButton"]
              : ["Catalog.ImportMenuPage.extractButton"]
          }
          intent="primary"
          loading={extractMenuState.isLoading}
          disabled={importImage == null}
          hidden={value != null}
          onClick={() => dispatch(extractMenu(importImage?.file!))}
        />
        <Button
          text="Save All"
          intent="success"
          hidden={value == null}
          loading={importMenuState.isLoading}
          onClick={() => dispatch(importMenu({ categories: value! }))}
        />
        <Button
          icon='trash'
          intent="danger"
          hidden={value == null}
          onClick={() => setValue(undefined)}
        />
      </PageHeader>
      <PageContent>
        {value == null ? (
          <>
            <Callout intent='primary' icon={<GoDependabot size={20} style={{ margin: "0px 7px -3px -27px" }} />}>
              <b>{t("calloutHeader")}</b>
              <br />{t("calloutMsg")}
            </Callout>
            <div className={styles.imageFrame}>
              <ExImageEditor
                name=""
                value={importImage}
                onChange={(e) => {
                  if (e.target.name === '.file')
                    setImportImage({ file: e.target.value } as any);
                }}
                frameProps={{
                  height: 500,
                  width: 400,
                }} />
            </div>
          </>
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            {value.map((category, categoryIndex) => (
              <Droppable droppableId={categoryIndex.toString()}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    className={styles.categoryFrame}
                    {...provided.droppableProps}>
                    <input
                      key={categoryIndex}
                      value={category.name}
                      type="text"
                      onChange={e => {
                        const newValue = value.slice();
                        newValue[categoryIndex] = {
                          name: e.target.value,
                          products: value[categoryIndex].products,
                        };
                        setValue(newValue);
                      }}
                    />
                    <Grid md={3} sm={2} xs={1} gap={20} >
                      {category.products.map((product, productIndex) =>
                        <Draggable
                          key={product.id}
                          draggableId={product.id}
                          index={productIndex}>
                          {(provided) => {
                            const classes = ['draggable'];
                            const _errors = _.get(constraintsErrors,
                              `categories.${categoryIndex}.products.${productIndex}`);
                            if (_errors)
                              classes.push('error');
                            return (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps} >
                                <ProductCard
                                  className={classes.join(' ')}
                                  value={product}
                                  onClick={() => setEditingProduct({
                                    _errors,
                                    _name: product.name,
                                    ...product,
                                  })}
                                />
                              </div>
                            );
                          }}
                        </Draggable>
                      )}
                    </Grid>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ))}
          </DragDropContext>
        )}
      </PageContent>
      <Dialog
        title={editingProduct?._name}
        isCloseButtonShown={true}
        isOpen={editingProduct != null}
        onClose={() => setEditingProduct(undefined)}>
        <DialogBody>
          <ProductForm
            style={{ margin: 10 }}
            value={editingProduct}
            errors={editingProduct?._errors}
            hiddenFields={["optionGroups", "image"]}
            onChange={(e) => {
              setEditingProduct({
                ...editingProduct!,
                [e.target.name]: e.target.value,
              })
            }}
          />
        </DialogBody>
        <DialogFooter
          actions={
            <Button
              text={["ok"]}
              intent="primary"
              onClick={() => {
                setValue(value!.map(category => ({
                  name: category.name,
                  products: category.products.map(product => {
                    if (product.id === editingProduct!.id)
                      return editingProduct!;
                    return product;
                  }),
                })));
                setEditingProduct(undefined);
              }}
            />
          }>
          <Button
            icon={"trash"}
            intent="danger"
            onClick={() => {
              setValue(value!.map(category => ({
                name: category.name,
                products: category.products.filter(product => product.id !== editingProduct!.id)
              })));
              setEditingProduct(undefined);
            }}
          />
        </DialogFooter>
      </Dialog>
    </>
  );
}
