/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useState } from "react";
import { LuPackageSearch } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

import {
  BackAsyncError, Backout, Button, Grid, GridContentLoader
} from "common/components";
import { PageContent, PageHeader } from "common/components/page";
import { ApiCallStateBuilder, isNotSuccessful } from "common/utils";
import OrderCard from 'order/components/OrderCard';
import { Order } from 'order/order.entities';
import { useLoadOrderHistory } from "order/order.hooks";

import styles from './styles.module.scss';


export default function () {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);

  const {
    loadOrderHistoryState,
    loadMoreOrderHistoryState,
    loadMoreOrderHistory,
    loadOrderHistory,
  } = useLoadOrderHistory({
    pageSize: 50,
  });

  return (
    <>
      <PageHeader
        icon={<LuPackageSearch size={23} style={{ marginRight: 1, marginTop: -1 }} />}
        title={['Order.HistoryPage.header']}
        searchPlaceholder={['Order.HistoryPage.searchPlaceholder']}
        onSearchQueryChange={(query) => {
          if (searchQuery?.length && query.length === 0)
            loadOrderHistory();
          setSearchQuery(query);
        }}
        onSearchQuerySubmit={() => {
          loadOrderHistory(searchQuery);
        }}>
        <Button
          icon="refresh"
          onClick={() => loadOrderHistory()}
        />
      </PageHeader>
      <PageContent>
        <ApiCallStateBuilder<Order[]>
          state={loadOrderHistoryState}
          onLoading={() => <GridContentLoader md={3} sm={2} xs={1} gap={20} />}
          onError={(error) => <BackAsyncError error={error} />}
          onValueEmpty={() => (
            <div style={{ marginTop: 150 }}>
              <Backout
                icon={<LuPackageSearch style={{ marginTop: -3, marginLeft: -2 }} />}
                title={["Order.HistoryPage.noOrdersFound"]}>
              </Backout>
            </div>
          )}
          onValueNotEmpty={(orders) =>
            <>
              <Grid md={3} sm={2} xs={1} gap={20} >
                {orders.map(order =>
                  <OrderCard
                    key={order.id}
                    value={order}
                    onClick={() => navigate(order.type + '/' + order.id!, { state: { backpath: '/order-history' } })}
                  />
                )}
              </Grid>
              <div className={styles.loadMoreButtonContainer}>
                <Button
                  intent="primary"
                  text={["loadMore"]}
                  hidden={
                    isNotSuccessful(loadOrderHistoryState) ||
                    loadOrderHistoryState.value!.length === 0 ||
                    loadOrderHistoryState.value!.length % 50 !== 0
                  }
                  loading={loadMoreOrderHistoryState.isLoading}
                  onClick={() => loadMoreOrderHistory(loadOrderHistoryState.value?.length || 0)}
                />
              </div>
            </>
          }
        />
      </PageContent>
    </>
  );
}
