/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';
import { BiCategory } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

import CategoryCard from 'category/components/CategoryCard';
import { BackAsyncError, Backout, Button, Grid, GridContentLoader } from "common/components";
import { ApiCallStateBuilder } from "common/utils";
import { Category } from '../../category.entities';
import { useSyncCategories } from "../../category.hooks";


export default function CategoriesGrid({ filterQuery }: CategoriesGridProps) {
  const navigate = useNavigate();
  const { syncCategoriesState, categories } = useSyncCategories({
    filter: (category) =>
      _.isEmpty(filterQuery) || _.values(category).join(" ").toLowerCase().includes(filterQuery!),
  });

  return (
    <ApiCallStateBuilder<Category[]>
      state={syncCategoriesState}
      onLoading={() => <GridContentLoader md={3} sm={2} xs={1} gap={20} />}
      onError={(error) => <BackAsyncError error={error} />}
      onValueEmpty={() => (
        <div style={{ marginTop: 150 }}>
          <Backout
            icon={
              <BiCategory style={{ marginTop: -3, marginLeft: -2 }} />
            }
            title={["Category.Grid.emptyBackoutTitle"]}>
            <div style={{ marginTop: 20 }}>
              <Button
                outlined
                text={["create"]}
                onClick={() => navigate("/categories/new")} />
            </div>
          </Backout>
        </div>
      )}
      onValueNotEmpty={() =>
        <Grid md={3} sm={2} xs={1} gap={20} >
          {categories.map(category =>
            <CategoryCard
              key={category.id}
              value={category}
              onClick={() => navigate(category.id!)}
            />
          )}
        </Grid>
      }
    />
  );
}

export type CategoriesGridProps = {
  filterQuery?: string | null;
}
