/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Outlet } from 'react-router-dom';

import { useIdentity } from 'auth/auth.hooks';
import { Logout } from 'auth/auth.store';
import { CreateBusiness, UpdateBusiness } from 'business/actions';
import { LoadMyBusinessesAction } from 'business/actions/load-my-businesses.action';
import { SetCurrentBusinessAction } from 'business/actions/set-current-business.action';
import { Business } from 'business/business.entities';
import BusinessForm from 'business/components/BusinessForm';
import {
  BackActivityIndicator, BackAsyncError, Backout, Card,
  CardHeader, FormButton,
} from "common/components";
import { FormProps } from 'common/components/form/Form';
import {
  ApiCallStateBuilder, isEmpty, useApiErrors, useApiState,
  useFormData,
} from "common/utils";
import { useEffect } from 'react';
import styles from "./styles.module.sass";


export default function ({ layout, ...formProps }: BusinessGuardProps) {
  const identity = useIdentity();

  const [saveState, dispatch] = useApiState((store) => store.business.save);
  const [loadMyBusinessesState] = useApiState((store) => store.business.loadMyBusinesses);
  const [setCurrentBusinessState] = useApiState((store) => store.business.setCurrentBusiness);

  const { formData, formErrors, setFormErrors, onChange, changes } =
    useFormData<Business>({
      category: 'restaurant',
      email: identity?.user?.email || '',
      ...identity?.business,
    }, null, []);
  useApiErrors(saveState, setFormErrors);

  useEffect(() => {
    if (identity != null &&
      identity.business == null &&
      isEmpty(loadMyBusinessesState)) {
      dispatch(LoadMyBusinessesAction());
    }
  }, [identity])

  useEffect(() => {
    if (identity != null &&
      identity.business == null &&
      loadMyBusinessesState.value &&
      loadMyBusinessesState.value.length > 0 &&
      isEmpty(setCurrentBusinessState)) {
      dispatch(SetCurrentBusinessAction(loadMyBusinessesState.value[0]));
    }
  }, [identity, loadMyBusinessesState])

  const child = identity?.business && setCurrentBusinessState.isLoading !== true
    ? <Outlet />
    : (
      <div className='fullscreenGuard' >
        <ApiCallStateBuilder
          state={loadMyBusinessesState}
          onLoading={() =>
            <Backout
              icon={<BackActivityIndicator compact />}
              message={["Business.Guard.loadingBackoutMsg"]} />
          }
          onError={(error) => <BackAsyncError
            error={error}
            onTryAgain={() => dispatch(LoadMyBusinessesAction())}
          />}
          onValueEmpty={() => (
            <Card className={styles.card} elevation={1} >
              <CardHeader
                title={["Business.Guard.header"]}
                right={
                  <FormButton
                    minimal
                    icon='cross'
                    onClick={() => dispatch(Logout())}
                  />
                }
              />
              <div className={styles.container}>
                <BusinessForm
                  className={styles.form}
                  value={formData}
                  errors={formErrors}
                  onChange={onChange}
                  disabledFields={formData.id ? [
                    "category",
                  ] : undefined}
                  {...formProps}>
                  <FormButton
                    key={'save_button'}
                    intent='primary'
                    text={['save']}
                    loading={saveState.isLoading}
                    onClick={() => formData.id
                      ? dispatch(UpdateBusiness(formData.id, changes, true))
                      : dispatch(CreateBusiness(formData, true))}
                  />
                </BusinessForm>
              </div>
            </Card>
          )}
          onValue={(data) =>
            <ApiCallStateBuilder
              state={setCurrentBusinessState}
              onLoading={() =>
                <Backout
                  icon={<BackActivityIndicator compact />}
                  message={[
                    "Business.Guard.loggingInAsBackoutMsg",
                    { business: setCurrentBusinessState.value },
                  ]} />
              }
              onError={(error) => <BackAsyncError
                error={error}
                onTryAgain={() => dispatch(SetCurrentBusinessAction(data[0]))}
              />}
            />
          }
        />
      </div>
    );
  return layout == null ? child : layout(child);
}

export type BusinessGuardProps = Omit<FormProps, "onChange"> & {
  layout?: (child: JSX.Element | null) => JSX.Element,
}