/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { AiOutlineBank } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import { Button } from "common/components";
import { PageContent, PageHeader } from "common/components/page";
import MerchantAccountsGrid from "merchant/common/containers/MerchantAccountsGrid";
import { useSyncMerchantAccounts } from "merchant/merchant.hooks";


export default function MerchantAccountsPage() {
  const navigate = useNavigate()
  const { merchants } = useSyncMerchantAccounts();

  return (
    <>
      <PageHeader
        icon={<AiOutlineBank size={20} style={{ marginRight: 2 }} />}
        title={['Merchant.ListPage.header']} >
        <Button
          text={[merchants.find(m => m.type === 'stripe')
            ? "create" : "Merchant.ListPage.createStripeButton"]}
          intent="primary"
          onClick={() => navigate('stripe/new')}
        />
      </PageHeader>
      <PageContent>
        <MerchantAccountsGrid />
      </PageContent>
    </>
  );
}
