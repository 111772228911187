/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';

import { useCallback, useEffect } from 'react';
import { FaRegUser } from "react-icons/fa";
import { useSearchParams } from 'react-router-dom';

import { UpdateProfileAction } from 'auth/actions/update-profile.action';
import { Profile } from 'auth/auth.entities';
import { useIdentity } from 'auth/auth.hooks';
import ProfileForm from 'auth/components/ProfileForm';
import { Button, Card, CardContent, Grid, Toast } from "common/components";
import { PageContent, PageHeader } from "common/components/page";
import {
  isLoading, isSuccessful, useApiErrors, useApiState, useFormData,
  useInitial,
} from "common/utils";

import styles from "./styles.module.scss";


export default function ProfilePage() {
  const initial = useInitial();
  const [searchParams] = useSearchParams();

  // store
  const identity = useIdentity();
  const [saveState, dispatch] = useApiState((state) => state.auth.saveProfile);

  // form data
  const { formData, formErrors, setFormErrors, onChange, changes } =
    useFormData<Profile>(identity?.user, null, [identity]);
  useApiErrors(saveState, setFormErrors);

  const updateProfile = useCallback(() => {
    dispatch(UpdateProfileAction(changes));
  }, [identity, changes])

  useEffect(() => {
    if (!initial && identity?.user && isSuccessful(saveState)) {
      Toast.showSuccess({ message: ["Profile.ItemPage.updatedToast"] });
    }
  }, [saveState]);

  return (
    <>
      <PageHeader
        icon={<FaRegUser size={23} style={{ marginTop: -2 }} />}
        title={identity?.user?.name}
        backButtonPath={searchParams.get("backButtonPath")}>
        <Button
          text={["save"]}
          intent="primary"
          onClick={updateProfile}
          hidden={_.isEmpty(changes)}
          loading={isLoading(saveState)}
        />
      </PageHeader>
      <PageContent className={styles.wbProfilePage}>
        <Grid md={2} xs={1} gap={20}>
          <Card elevation={1}>
            <CardContent>
              <ProfileForm
                value={formData}
                errors={formErrors}
                onChange={onChange}
              />
            </CardContent>
          </Card>
        </Grid>
      </PageContent>
    </>
  );
}
