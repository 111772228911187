/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';
import moment from 'moment';

import { Popover2 } from '@blueprintjs/popover2';
import { useTranslation } from 'react-i18next';
import { BiStore, BiWallet } from "react-icons/bi";
import { FaCar, FaMapPin, FaWalking } from "react-icons/fa";
import { FaPersonCircleQuestion } from "react-icons/fa6";
import { IoMdBicycle } from "react-icons/io";
import { LiaShippingFastSolid } from "react-icons/lia";
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import CartItemCard from 'catalog/components/CartItemCard';
import {
  Alert, BackActivityIndicator, BackAsyncError, Button,
  Callout, Card, CardContent, CardHeader, CardItem, Grid,
  Menu, MenuItem, NumericField, PageContent, PageHeader,
  PageNotFound, Tag, Toast,
} from 'common/components';
import {
  ApiCallState, ApiCallStateBuilder, formatExPhone, isUseless,
  useApiState, useInitial,
} from 'common/utils';
import GoogleMap, { GoogleMapInstance, GoogleMapMarker } from 'geo/components/GoogleMap';
import { useSyncVisitorLocation } from 'geo/geo.hooks';
import { CancelOrder } from 'order/actions/cancel-order.action';
import { CompleteOrder } from 'order/actions/complete-order.action';
import { ConfirmOrder } from 'order/actions/confirm-order.action';
import { loadOrder as loadOrderAction } from 'order/actions/load-order.action';
import OrderPaymentTag from 'order/components/OrderPaymentTag';
import OrderStatusTag from 'order/components/OrderStatusTag';
import { PickupOrder } from 'order/order.entities';
import { useEffect, useMemo, useState } from 'react';

import { EditEtrAction } from 'order/actions/edit-order.action';
import PickupMethodTag from 'order/components/OrderTypeTag';
import { useCreatedAgo, useEtaString, useEtrString, usePreferredEtrString } from 'order/order.hooks';
import styles from './styles.module.scss';


export default function () {
  const { id } = useParams();
  const { t } = useTranslation();
  const initial = useInitial();
  const navigate = useNavigate();
  const { state } = useLocation();

  // api state
  const [loadOrderStateMap] = useApiState((store) => store.order.loadOrderMap);
  const [cancelState, dispatch] = useApiState((store) => store.order.cancel);
  const [confirmState] = useApiState((store) => store.order.confirm);
  const [completeState] = useApiState((store) => store.order.complete);
  const loadOrderState = (loadOrderStateMap[id!] || {}) as ApiCallState<PickupOrder>;
  const order = loadOrderState.value;
  const loadOrder = () => dispatch(loadOrderAction(id!))
  const { syncVisitorLocationsState } = useSyncVisitorLocation(id!);

  // page state
  const [isCancellationDialogVisible, showCancallationDialog] = useState(false);
  const [isConfirmationDialogVisible, showConfirmationDialog] = useState(false);
  const [isEditEtrDialogVisible, showEditEtrDialog] = useState(false);
  const [isCompleteDialogVisible, showCompleteDialog] = useState(false);
  const [etr, setEtr] = useState(10);

  useEffect(() => {
    if (isUseless(loadOrderState))
      loadOrder();
  }, [id, loadOrderState]);

  // default etr
  useEffect(() => {
    if (order != null) {
      const maxEtrItem = _.maxBy(order.cart.items, (i) => i.product.etr || -1);
      if (maxEtrItem?.product?.etr) {
        setEtr(maxEtrItem.product.etr);
      }
    }
  }, [order == null]);

  // handle cancel state
  useEffect(() => {
    if (!initial) {
      if (cancelState.value) {
        Toast.showSuccess({
          message: ["Order.ItemPage.cancelledToast", { entity: cancelState.value }],
        });
        navigate('/orders');
      } else if (cancelState.error) {
        Toast.showApiError(cancelState.error);
      }
    }
  }, [cancelState]);

  // handle confirm state
  useEffect(() => {
    if (!initial) {
      if (confirmState.error) {
        Toast.showApiError(confirmState.error);
      } else if (confirmState.value) {
        showConfirmationDialog(false);
      }
    }
  }, [confirmState]);

  // handle complete state
  useEffect(() => {
    if (!initial) {
      if (completeState.error) {
        Toast.showApiError(completeState.error);
      } else if (completeState.value) {
        showCompleteDialog(false);
      }
    }
  }, [completeState]);

  const [map, setMap] = useState<null | GoogleMapInstance>(null);
  const [mapCenter, setMapCenter] = useState({ lat: -28.0167, lng: 153.3981 })
  const [mapZoom, setMapZoom] = useState(13);
  useEffect(() => {
    if (order) {
      setMapCenter({
        lat: order.destination.latitude,
        lng: order.destination.longitude,
      });
    }
  }, [order == null]);

  const visitorMarker = useMemo(() => {
    if (syncVisitorLocationsState?.value == null || order == null)
      return null;

    const icon = order.visitorActivity?.type === 'in-vehicle'
      ? FaCar
      : order.visitorActivity?.type === 'on-foot'
        ? FaWalking
        : order.visitorActivity?.type === 'on-bicycle'
          ? IoMdBicycle
          : FaPersonCircleQuestion;

    if (map) {
      setTimeout(() => {
        const bounds = new window.google.maps.LatLngBounds();
        bounds.extend(mapCenter);
        bounds.extend({
          lat: syncVisitorLocationsState.value!.location.latitude,
          lng: syncVisitorLocationsState.value!.location.longitude,
        });
        map.fitBounds(bounds, 90);

        const zoom = map.getZoom();
        if (mapZoom !== zoom) {
          setMapZoom(zoom!);
        }
        const center = map.getCenter();
        if (mapCenter.lat !== center?.lat() || mapCenter.lng !== center?.lng()) {
          setMapCenter({ lat: center!.lat(), lng: center!.lng() });
        }
      }, 500);
    }

    return (
      <GoogleMapMarker
        key="visitor"
        position={{
          lat: syncVisitorLocationsState.value!.location.latitude,
          lng: syncVisitorLocationsState.value!.location.longitude,
        }}
        icon={{
          path: icon({}).props.children[0].props.d,
          fillColor: visitorIconColor(order),
          fillOpacity: 1,
          strokeWeight: 2,
          strokeColor: "#555",
          scale: 0.1,
          anchor: { x: 250, y: 400, equals: () => false },
        }}
      />
    );
  }, [syncVisitorLocationsState, order, map]);

  const createdAgo = useCreatedAgo(order);
  const etrString = useEtrString(order);
  const preferredEtrString = usePreferredEtrString(order,
    ((minsAgo) => t("Duration.ago", { duration: t("Duration.minutes", { count: -minsAgo }) })));
  const etaString = useEtaString(order as PickupOrder);

  return (
    <>
      <PageHeader
        title={order?.number}
        backButtonPath={state?.backpath || "/orders"}>
        <Button
          text={["confirm"]}
          intent="success"
          onClick={() => {
            if (order?.preferredEtr) {
              setEtr(moment(order.preferredEtr).diff(moment(), 'minutes') - 1)
            }
            showConfirmationDialog(true);
          }}
          hidden={!order || order.status !== 'created'}
        />
        <Button
          text={["Order.ItemPage.editEtrButton"]}
          intent="primary"
          onClick={() => {
            if (order?.preferredEtr) {
              setEtr(moment(order.preferredEtr).diff(moment(), 'minutes') - 1)
            }
            showEditEtrDialog(true);
          }}
          hidden={!order || !['active', 'created'].includes(order.status) || !order.etr}
        />
        <Popover2
          placement="bottom-end"
          interactionKind="click"
          content={
            <Menu>
              <MenuItem
                icon='issue-closed'
                text={["complete"]}
                intent="none"
                onClick={() => showCompleteDialog(true)}
                hidden={!order || order.status !== 'active'}
              />
              <MenuItem
                icon="cross-circle"
                text={["cancel"]}
                intent="danger"
                onClick={() => showCancallationDialog(true)} />
            </Menu>
          }>
          <Button
            minimal
            rightIcon="menu"
            hidden={[
              'pending',
              'prepared',
              'created',
              'active',
            ].includes(order?.status || 'none') === false}
          />
        </Popover2>
      </PageHeader>
      <PageContent>
        <ApiCallStateBuilder state={loadOrderState}
          onLoading={() => <BackActivityIndicator />}
          onError={(error) => <BackAsyncError error={error} onTryAgain={loadOrder} />}
          onValueNull={() => <PageNotFound />}
          onValue={(order) => (
            <>
              {['created', 'active'].includes(order.status) && (
                <Card className={styles.mapCard}>
                  <GoogleMap
                    onInit={setMap}
                    options={{
                      center: mapCenter,
                      zoom: mapZoom,
                      mapTypeControl: false,
                      streetViewControl: false,
                      zoomControl: false,
                    }} >
                    <GoogleMapMarker
                      key="destination"
                      position={{
                        lat: order.destination.latitude,
                        lng: order.destination.longitude,
                      }}
                      icon={{
                        path: FaMapPin({}).props.children[0].props.d,
                        fillColor: "#FF835B",
                        fillOpacity: 1,
                        strokeWeight: 2,
                        strokeColor: "#555",
                        scale: 0.08,
                        anchor: { x: 150, y: 400, equals: () => false },
                      }}
                    />
                    {visitorMarker}
                  </GoogleMap>
                </Card >
              )}
              <Grid md={2} xs={1} gap={20}>
                <Card>
                  <CardContent>
                    <CardItem
                      icon="application"
                      text={["status"]}
                      right={
                        <>
                          <OrderStatusTag order={order} />
                          {order.status === 'active' && order.visitorActivity?.lastTrackedAt == null && (
                            <Tag
                              minimal
                              className={styles.orderStatusTag}
                              intent="warning">Manual Location Tracking</Tag>
                          )}
                        </>
                      }
                    />
                    <CardItem
                      icon='calendar'
                      text={["createdAt"]}
                      right={
                        <span>
                          {moment(order.createdAt).local().format(t("Formats.time"))}, {createdAgo}
                        </span>
                      }
                    />
                    {order.preferredEtr != null && (
                      <CardItem
                        icon='time'
                        text={["pickupTime"]}
                        right={
                          <span className={styles.preferredEtr}>
                            {moment(order.preferredEtr).local().format(t("Formats.time"))}, {preferredEtrString}
                          </span>
                        }
                      />
                    )}
                    {order.etr != null && (
                      <CardItem
                        icon='time'
                        text={["etr"]}
                        right={
                          <span>
                            {moment(order.etr).local().format(t("Formats.time"))}, {etrString}
                          </span>
                        }
                      />
                    )}
                    {etaString != null && (
                      <CardItem
                        icon='time'
                        text={["eta"]}
                        right={etaString}
                      />
                    )}
                    <CardItem
                      icon={<BiStore size={18} style={{ marginBottom: -4, marginLeft: -1, marginRight: -2 }} />}
                      text={["branch"]}
                      right={
                        <a href={`/branches/${order.branch.id}`} target='_blank'>
                          {order.branch.name}
                        </a>
                      }
                    />
                    <CardItem
                      icon="user"
                      text={["customer"]}
                      right={
                        <>
                          {order.customer.phone ? (
                            <a href={`tel:${formatExPhone(order.customer.phone)}`}>
                              {formatExPhone(order.customer.phone)}
                            </a>
                          ) : (
                            <a href={`mailto:${order.customer.email}`}>
                              {order.customer.email}
                            </a>
                          )}
                          {order.customer.name ? ` (${order.customer.name})` : ''}
                        </>
                      } />
                    <CardItem
                      hidden={true}
                      icon="route"
                      text={["destination"]}
                      right={
                        <a
                          href={`https://www.google.com/maps/place/${order.destination.latitude},${order.destination.longitude}`}
                          target='_blank'>
                          {order.destination.displayAs}
                        </a>
                      } />
                    <CardItem
                      icon="drive-time"
                      text={["vehicle"]}
                      hidden={_.isEmpty(order.vehicle)}
                      right={<span>{order.vehicle}</span>} />
                    {order.type === 'pickup' && (
                      <CardItem
                        icon={<LiaShippingFastSolid size={18} style={{ marginTop: 3, marginRight: -2 }} />}
                        text={["Pickup Method"]}
                        right={
                          <PickupMethodTag
                            order={order as PickupOrder}
                          />
                        }
                      />
                    )}
                    <CardItem
                      icon={<BiWallet size={18} style={{ marginBottom: -2 }} />}
                      text={["payment"]}
                      right={<OrderPaymentTag order={order} />} />
                    <CardItem
                      icon="dollar"
                      text={["totalPrice"]}
                      right={order.cart.totalPrice + ' ' + order.payment!.merchant.currency} />
                    <CardItem
                      icon="info-sign"
                      text={["specialInstractions"]}
                      hidden={_.isEmpty(order.note)}
                      right={<span className={styles.note}>{order.note}</span>} />
                  </CardContent>
                </Card>
                <Card>
                  <CardHeader
                    title={["cart"]}
                    right={
                      <span className={styles.totalPrice}>
                        {order.cart.totalPrice.toFixed(2).padEnd(2, '0')}
                        &nbsp;{order.payment!.merchant.currency}
                      </span>
                    }
                  />
                  <CardContent className={styles.cartItemCards}>
                    {order.cart.items
                      .map((item) => {
                        return (
                          <CartItemCard
                            key={item.product.id}
                            interactive={false}
                            value={item}
                            onClick={() => {
                              if (item.product.id) {
                                window.open('/products/' + item.product.id, "_blank");
                              } else {
                                Toast.showPrimary({
                                  message: ["Order.ItemPage.manualItemToast", { item: item.product.name }],
                                  timeout: 900,
                                });
                              }
                            }}
                          />
                        )
                      })}
                  </CardContent>
                </Card>
              </Grid>
            </>
          )}
        />
        <Alert
          isOpen={isCancellationDialogVisible}
          confirmButtonText={['cancel']}
          cancelButtonText={['nope']}
          icon="cross-circle"
          intent="danger"
          loading={cancelState.isLoading}
          onCancel={() => showCancallationDialog(false)}
          onConfirm={() => dispatch(CancelOrder(id!))}>
          <p>{t("Order.ItemPage.cancelDialogBody")}</p>
        </Alert>
        <Alert
          isOpen={isCompleteDialogVisible}
          confirmButtonText={['complete']}
          cancelButtonText={['nope']}
          icon="issue-closed"
          intent="none"
          loading={completeState.isLoading}
          onCancel={() => showCompleteDialog(false)}
          onConfirm={() => dispatch(CompleteOrder(id!))}>
          <>
            <p>{t("Order.ItemPage.completeDialogBody")}</p>
            {order?.payment?.method?.offline && (
              <Callout intent='warning' style={{ marginTop: 20, marginBottom: 10 }}>
                {["Order.ItemPage.completeDialogOfflinePaymentCallout"]}
              </Callout>
            )}
          </>
        </Alert>
        <Alert
          isOpen={isConfirmationDialogVisible}
          confirmButtonText={['confirm']}
          cancelButtonText={['nope']}
          icon="endorsed"
          intent="success"
          loading={confirmState.isLoading}
          onCancel={() => showConfirmationDialog(false)}
          onConfirm={() => {
            if (etr < 0) {
              Toast.showDanger({ message: ['Order.ItemPage.invalidEtrToast'] });
            } else {
              dispatch(ConfirmOrder(id!, etr));
            }
          }}>
          <p>Confirm this order?</p>
          <NumericField
            selectAllOnFocus
            className={styles.etrField}
            name='etr'
            label={["Order.ItemPage.editEtrDialogEtrField"]}
            value={etr}
            onChange={({ target: { value } }) => {
              setEtr(value || 0);
            }}
          />
        </Alert>
        <Alert
          isOpen={isEditEtrDialogVisible}
          confirmButtonText={['save']}
          cancelButtonText={['back']}
          icon="endorsed"
          intent="success"
          loading={confirmState.isLoading}
          onCancel={() => showEditEtrDialog(false)}
          onConfirm={() => {
            if (etr < 0) {
              Toast.showDanger({ message: ['Order.ItemPage.invalidEtrToast'] });
            } else {
              dispatch(EditEtrAction(id!, etr + 1));
              showEditEtrDialog(false);
            }
          }}>
          <p>{t("Order.ItemPage.editEtrDialogBody")}</p>
          <NumericField
            selectAllOnFocus
            className={styles.etrField}
            name='etr'
            label={["Order.ItemPage.editEtrDialogEtrField"]}
            value={etr}
            onChange={({ target: { value } }) => {
              setEtr(value || 0);
            }}
          />
        </Alert>
      </PageContent >
    </>
  );
}

const visitorIconColor = (order?: PickupOrder) => {
  switch (order?.status) {
    case 'prepared':
    case 'created':
    case 'active': {
      if (order?.visitorActivity?.status === 'en-route')
        return '#4E9D63';
      if (order?.visitorActivity?.status === 'arrived')
        return '#0F75BC';
      return '#FF835B';
    }
    case 'cancelled':
    case 'declined':
      return '#DE4A65';
    default:
      return '#737373';
  }
}
