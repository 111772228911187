/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { useState } from "react";
import { RiBookLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

import CatalogsGrid from "catalog/containers/CatalogsGrid";
import { Button } from "common/components";
import { PageContent, PageHeader } from "common/components/page";


export default function CatalogsPage() {
  const navigate = useNavigate()
  const [searchQuery, setSearchQuery] = useState<string | null>(null);

  return (
    <>
      <PageHeader
        icon={<RiBookLine size={23} style={{ marginRight: 1, marginTop: -1 }} />}
        title={['catalogs']}
        onSearchQueryChange={setSearchQuery} >
        <Button
          text={["create"]}
          intent="success"
          onClick={() => navigate('new')}
        />
      </PageHeader>
      <PageContent>
        <CatalogsGrid filterQuery={searchQuery} />
      </PageContent>
    </>
  );
}
