/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import _ from 'lodash';

import { Popover2 } from '@blueprintjs/popover2';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { CreateCatalog } from 'catalog/actions/create-catalog.action';
import { DeleteCatalog } from 'catalog/actions/delete-catalog.action';
import { UpdateCatalog } from 'catalog/actions/update-catalog.action';
import { Catalog } from 'catalog/catalog.entities';
import { useSyncCatalogs } from 'catalog/catalog.hooks';
import CatalogForm from 'catalog/components/CatalogForm';
import { Category } from 'category/category.entities';
import { useSyncCategories } from 'category/category.hooks';
import CategoryCard from 'category/components/CategoryCard';
import {
  Alert, BackActivityIndicator, BackAsyncError, Backout, Button,
  Card, CardContent, CardHeader, Column, DragAndDropOrderList, Grid,
  Menu, MenuItem, PageContent, PageHeader, PageNotFound, Row,
  SelectField, Toast
} from 'common/components';
import {
  ApiCallStateBuilder, isLoading, isSuccessful, useApiErrors,
  useApiState, useFormData, useInitial
} from 'common/utils';
import styles from './styles.module.scss';


export default function CatalogPage() {
  const { id } = useParams();
  const { t } = useTranslation();
  const initial = useInitial();
  const navigate = useNavigate();

  // store
  const { syncCatalogsState, catalog } = useSyncCatalogs({
    find: catalog => catalog.id === id,
  });
  const [saveState, dispatch] = useApiState((store) => store.catalog.save);
  const [deleteState] = useApiState((store) => store.catalog.delete);

  // state
  const [isConfirmingDeletion, confirmDeletion] = useState(false);
  const [isCategoryPickerOpen, setCategoryPickerOpen] = useState(false);

  // form data
  const { formData, onChange, formErrors, changes, setFormErrors } =
    useFormData<Catalog>(catalog, () => ({
      type: 'internal',
      available: true,
      categories: [],
    }), [catalog]);
  useApiErrors(saveState, setFormErrors);

  // store
  const { syncCategoriesState, categories } = useSyncCategories({
    filter: (c) => formData.categories.includes(c.id!) === true,
  })

  // effects
  useEffect(() => {
    if (!initial) {
      if (isSuccessful(saveState)) {
        if (id === "new") {
          navigate("/catalogs");
          Toast.showSuccess({
            message: ["Catalog.ItemPage.addedToast", { entity: saveState.value }],
          });
        } else {
          Toast.showSuccess({ message: ["Catalog.ItemPage.updatedToast"] });
        }
      }
    }
  }, [saveState]);
  useEffect(() => {
    if (!initial) {
      if (deleteState.value) {
        Toast.showSuccess({
          message: ["Catalog.ItemPage.addedToast", { entity: deleteState.value }],
        });
        navigate('/catalogs');
      } else if (deleteState.error) {
        Toast.showApiError(deleteState.error);
      }
    }
  }, [deleteState]);

  return (
    <>
      <PageHeader
        title={catalog?.name || ["Catalog.ItemPage.newHeader"]}
        backButtonPath="/catalogs">
        <Button
          text={["create"]}
          intent="success"
          onClick={() => dispatch(CreateCatalog(formData))}
          hidden={id !== 'new' || _.isEmpty(changes)}
          loading={isLoading(saveState)}
        />
        <Button
          text={["save"]}
          intent="success"
          onClick={() => dispatch(UpdateCatalog(id!, changes))}
          hidden={id === 'new' || _.isEmpty(changes)}
          loading={isLoading(saveState)}
        />
        <Popover2
          placement="bottom-end"
          interactionKind="click"
          content={
            <Menu>
              <MenuItem
                icon="trash"
                text={["delete"]}
                intent='danger'
                hidden={catalog == null}
                disabled={catalog == null}
                onClick={() => confirmDeletion(true)} />
            </Menu>
          }>
          <Button minimal rightIcon="menu" />
        </Popover2>
      </PageHeader>
      <PageContent>
        <ApiCallStateBuilder state={syncCatalogsState}
          onLoading={() => <BackActivityIndicator />}
          onError={(error) => <BackAsyncError error={error} />}
          onValue={() => {
            if (!catalog && id !== "new")
              return <PageNotFound />
            return (
              <ApiCallStateBuilder state={syncCategoriesState}
                onLoading={() => <BackActivityIndicator />}
                onError={(error) => <BackAsyncError error={error} />}
                onSuccess={() =>
                  <Grid md={2} xs={1} gap={20}>
                    <Card>
                      <CardContent>
                        <CatalogForm
                          value={formData}
                          errors={formErrors}
                          readOnly={isLoading(saveState)}
                          onChange={onChange}
                        />
                      </CardContent>
                    </Card>
                    <Card>
                      <CardHeader title={["categories"]} />
                      <CardContent className={styles.categoryCards} >
                        <SelectField<Category>
                          name="categoryPicker"
                          value={undefined}
                          items={syncCategoriesState.value!.filter(p => formData.categories.includes(p.id!) === false)}
                          popoverProps={{
                            isOpen: isCategoryPickerOpen,
                            onInteraction: setCategoryPickerOpen,
                          }}
                          onChange={(event) => {
                            const newCategories = formData.categories.slice();
                            newCategories.push(event.target.value.id!);
                            onChange({
                              target: {
                                name: "categories",
                                value: newCategories,
                              }
                            })
                          }}
                          buttonRenderer={(category) => category == null
                            ? t("Catalog.ItemPage.addCategory")
                            : <MenuItem icon="box" text={category!.name} />
                          }
                          itemPredicate={(query, category) =>
                            _.values(category).join(' ').toLowerCase().includes(query.toLowerCase())
                          }
                          itemRenderer={(category, props) =>
                            <CategoryCard
                              key={category.id}
                              className={styles.selectCategoryCard}
                              value={category}
                              onClick={props.handleClick}
                              onFocus={props.handleFocus}
                            />
                          } />
                        {categories.length === 0
                          ? <Backout message={["Catalog.ItemPage.noCategories"]} />
                          : <DragAndDropOrderList<Category>
                            id='categoryList'
                            name='categories'
                            values={formData.categories
                              .map(id => categories.find(c => c.id === id))
                              .filter(item => item != null) as Category[]}
                            onChange={({ target: { name, value } }) =>
                              onChange({
                                target: {
                                  name,
                                  value: value.map(v => v.id),
                                }
                              })
                            }
                            renderItem={(category) => (
                              <Row mainAxis='space-between' crossAxis='center'>
                                <CategoryCard className='draggable' value={category} />
                                <Column className={styles.listItemActions}>
                                  <Button
                                    minimal
                                    icon="share"
                                    onClick={() => window.open(`/categories/${category.id}`, "_blank")}
                                  />
                                  <Button
                                    minimal
                                    icon="trash"
                                    onClick={() => {
                                      const newCategories = formData.categories.filter(pid => category.id !== pid);
                                      onChange({
                                        target: {
                                          name: "categories",
                                          value: newCategories,
                                        }
                                      })
                                    }}
                                  />
                                </Column>
                              </Row>
                            )}
                          />
                        }
                      </CardContent>
                    </Card>
                  </Grid>
                } />
            );
          }} />
        <Alert
          isOpen={isConfirmingDeletion}
          confirmButtonText={['delete']}
          cancelButtonText={['nope']}
          icon="trash"
          intent="danger"
          loading={isLoading(deleteState)}
          onCancel={() => confirmDeletion(false)}
          onConfirm={() => dispatch(DeleteCatalog(id!))}>
          <p>Delete this catalog?</p>
        </Alert>
      </PageContent>
    </>
  );
}
